import React, { useEffect, useState } from 'react';
import "./Style/OffersNav.css";
import "./Style/Navbar.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {viewAllPackages} from '../Utilis/BaseUrl.js'
import slugify from 'slugify';





function Offers_Nav() {

      //   list all packages
      const [getallPackages,setallAPackages] = useState([])
      const ListAllPackages = async ()=>{
        try{

            const response = await axios.get(`${viewAllPackages}`);
            if(response){
                setallAPackages(response.data.data);                               
            }
        }catch(error){
            // console.log(error)
        }
      }

  useEffect(()=>{
    window.scrollTo({
      top:0
    })
    ListAllPackages()
  },[])

      //findng heighest offer---------
      const findTopStudent = (getallPackages) => {
        const eligibleStudents = getallPackages?.filter(getallPackages => getallPackages?.is_active === true && getallPackages?.public_enabled === true);
        if (eligibleStudents.length == 0) 
        return null;
        return eligibleStudents.reduce((prev, current) => (prev.package_offer > current.package_offer) ? prev : current);
      };
      const topStudent = findTopStudent(getallPackages);
         //findng heighest offer---------end


    //view specific packages 
    const navigate = useNavigate();
    const handlepackage =(id , package_name)=>{
      sessionStorage.setItem('Package_id',id);
      const slug = slugify(package_name)
        navigate (`/packagebooking/${slug}/${id}`)
  
    }

    const packageOfferFilter = getallPackages.filter((offerFilter)=>
      offerFilter.package_offer > '0' && offerFilter.package_offer != ''  && offerFilter.public_enabled == true  && offerFilter.is_active == true
    );
  // console.log("first",props.data.package_name)

  return (
    <>
    {packageOfferFilter.length > 0 && 
    <div className='offer-row flex justify-start   ' >
    <div className='h-auto  mx-auto my-2  w-[1184px]  text-white 
                     max-xl:w-[950px]
                     max-lg:w-[700px]
                     max-md:w-[500px]
                     max-sm:px-[16px]   max-sm:w-[100%] max-sm:h-[72px] max-sm:text-[10px] cursor-pointer'
                     onClick={()=>handlepackage(topStudent?.id , topStudent?.package_name)}>
        Offer of the week - <strong>{topStudent?.package_name} {topStudent?.package_duration_day} days package- {topStudent?.package_offer}%</strong> Off 	&rarr;
    </div>

    </div>
}
    </>
  )
}

export default Offers_Nav
