import React from 'react';
import './Style/Footer.css';
import { Link } from 'react-router-dom'
// import Logo from './Images/logos.png';
import Footer_logo from './Images/footer_logo.png';
// import Twitter from './Images/Vector.png';
import Facebook from './Images/facebook.png';
import LinkedIn from './Images/Social_icon.png';
import Title from './Images/title.png'
import XLogo from './Images/x-logo.png'
import Instagram from './Images/instagram.png'




function Footer() {
   
    
    const phoneNumber = process.env.REACT_APP_PHONE_NUMBER
    const url = `https://wa.me/${phoneNumber}`;

    return (
        <div>
            <div className='footer-div  bg-[#141414]'>
                <div className='footer-container  h-[auto]   mx-auto max-sm:px-[16px] pb-[40px] 
                                  w-[1184px] 
                                    max-xl:w-[980px]
                                     max-lg:w-[700px]
                                     max-md:w-[550px] 
                                     max-sm:w-[100%]
                                     '>
                    <div className='footer-container-details-row px-[80px] pt-[64px]  pb-[48px]  
                                    max-lg:px-[15px]
                                    max-md:pt-[30px]
                                    max-sm:px-[5px]'>
                        <div className='footer-container-details px-[28px]  
                                        max-lg:px-[10px]
                                        max-sm:px-[5px]'>
                            <div className='footer-details-row   '>
                                <div className=' flex flex-col  '>
                                <div className='footer-company-details '>

                                    <div className='footer-comapany-logo   '>

                                        <div className='footer-comapany-logo-image '>

                                            <img src={Footer_logo} alt='' />

                                        </div>
                                        <h5  style={{ padding: '12px' }}>trippers maldives</h5>

                                    </div>
                                    {/* <h2>Tourism Company in Maldives.</h2> */}
                                </div>
                                <div >
                                    <img src={Title} className='w-[180px] 
                                                                max-lg:w-[160px] max-lg:mt-[10px]
                                                                 max-md:w-[130px] 
                                                                 max-sm:w-[100px] max-sm:mb-[10px]
                                                                '  alt='' />
                                </div>
                                </div>
                                <div className='footer-company-link  '>
                                    <div className='footer-company-link-comapny'>
                                        <div style={{ height: '90px' }}>
                                            <h4 style={{
                                                margin: '0',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                               
                                                color: '#98A2B3' 
                                            }} className='max-sm:leading-[10px] leading-[20px]'>Company</h4>
                                            <div className='footer-company-link-comapny-details  text-nowrap'>
                                                <Link to="/AboutAs" id='about-page' style={{ textDecoration: 'none', color: '#EAECF0;' }}>
                                                    <h6>About us</h6>
                                                </Link>
                                                {/* <Link to="" style={{ textDecoration: 'none' }}>
                                                    <h6>Careers</h6>
                                                </Link> */}
                                                {/* <a href={url} target="_blank" rel="noopener noreferrer"  id='contact-us'>
                                                    <h6>Contact us</h6>
                                                 </a> */}
                                                   <Link to="/contact-us" id='about-page' style={{ textDecoration: 'none', color: '#EAECF0;' }}>
                                                    <h6>Contact us</h6>
                                                </Link>
                                                
                                            </div>

                                        </div>
                                    </div>
                                    <div className='footer-company-link-social'>
                                        <div style={{ height: '110px' }}>
                                            <h4 style={{
                                                margin: '0',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                color: '#98A2B3'
                                            }} className='max-sm:leading-[10px] leading-[20px]'>Social</h4>
                                            <div className='footer-company-link-comapny-details'>                                               
                                                <a href="https://instagram.com/trippersmaldives" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} id='twitter-link'>
                                                    <h6>Instagram</h6>
                                                </a>
                                                <a href="https://fb.com/trippersmaldives" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} id='twitter-link'>
                                                    <h6>Facebook</h6>
                                                </a>
                                                <a href="https://x.com/trippersmv" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} id='twitter-link'>
                                                    <h6>X</h6>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='footer-company-link-legal'>
                                        <div style={{ height: '100px' }}>
                                            <h4 style={{
                                                margin: '0',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                color: '#98A2B3'
                                            }} className='max-sm:leading-[10px] leading-[20px]'>Legal</h4>
                                            <div className='footer-company-link-comapny-legal'>
                                                <Link to="/Terms" style={{ textDecoration: 'none' }} id='terms-page'>
                                                    <h6>Terms</h6>
                                                </Link>
                                                <Link to="/Privacy" style={{ textDecoration: 'none' }} id='privacy-page'>
                                                    <h6>Privacy</h6>
                                                </Link>
                                                {/* <Link to="" style={{ textDecoration: 'none' }}>
                                                    <h6>Cookies</h6>
                                                </Link>
                                                <Link to="" style={{ textDecoration: 'none' }}>
                                                    <h6>Settings</h6>
                                                </Link> */}

                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='footer-copyright-div'>
                        <div >
                            <h5>© 2024 Trippers Maldives. All rights reserved.</h5>
                            <div className='footer-social-media-div w-[120px]   flex justify-between '>

                                <a href="https://x.com/trippersmv" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} id='twitter-link'>
                                    <img    src={XLogo} alt="" /> 
                                </a>
                                <a href="https://instagram.com/trippersmaldives" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} id='twitter-link'>
                                    <img src={Instagram} alt='' />
                                </a>
                                <a href="https://fb.com/trippersmaldives" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} id='twitter-link'>
                                    <img src={Facebook} alt='' />
                                </a>
                            </div>
                        </div>
                        <h1 className='text-[110px] leading-[50px]
                                       max-xl:text-[90px] max-xl:leading-[38px]
                                       max-lg:text-[50px] max-lg:leading-[1px]
                                       max-md:text-[0px] max-md:leading-[0px]
                                       max-sm:text-[0px] max-sm:leading-[0px]'>trippers maldives</h1>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Footer
