import React from 'react'
import PageNotFoundImage from './Images/404-page.png'
import { useNavigate } from 'react-router-dom'

function PageNotFound() {
  const navigate = useNavigate()
  return (
    <div className='flex justify-center items-center min-h-[100vh]'>
      <div className='flex flex-col items-center justify-center'>
      <img width={120} src={PageNotFoundImage} alt="Page Not Found" />
      <h1 className='mt-2 font-[600] text-[18px] text-[#9e9e9e]'>Sorry, The page is not found</h1>
      <button className='bg-[#de6a29] mt-3 text-[white] py-2 px-5 rounded-[8px] ' onClick={(()=>(navigate('/')))}>Ok</button>
      </div>
    </div>

  )
}

export default PageNotFound