import React, { useState } from 'react'
import { Modal } from 'antd'
import Footer from './Footer'
import share from './Images/share.png'
import {
    EmailShareButton,
    FacebookShareButton,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
  } from "react-share";
  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
    XIcon,
  } from "react-share";
  


function ShareUrl(props) {
    const [visible ,setvisible] = useState(false)
    const [copyUrl ,setcopyUrl] = useState(false)

  
    const handleCopy = () => {
        navigator.clipboard.writeText(props.Url)
          .then(() => {
            // alert('Copied to clipboard!');
            setcopyUrl(true)
            setTimeout(() => {
                setcopyUrl(false)
                }, 5000);

          })
          .catch((err) => {
            // console.error('Failed to copy text');
          });
      };
    //   console.log("first",copyUrl)


  return (
    <div>
    <button className='flex items-center justify-center gap-x-1 bg-[#EFEFEF] w-[89px] h-[32px] rounded-[43px]' onClick={()=>setvisible(true)} >
        <img src={share} className='w-[18px]' alt=''/>
        <h5 className='font-[400] text-[14px]'>Share</h5>
    </button>


    <Modal onCancel={()=>{setvisible(false); setcopyUrl(false)}}
    footer={null}
    visible={visible}
    width={450}>
    <div className='my-3'>
        <div className='flex flex-col items-center justify-center gap-y-4 mb-3 ' style={{fontFamily:'"Montserrat", sans-serif'}} >
            <h1 className='block text-[20px] font-[600] text-[#5c5c5c]'>Share This {props.name}</h1>
            <div className='flex  gap-x-3'>
                <EmailShareButton url={props.Url}>
                    <EmailIcon size={38} round={true}/>
                </EmailShareButton>
                <FacebookShareButton  url={props.Url}>
                    <FacebookIcon size={38} round={true}/>
                </FacebookShareButton>
                <TwitterShareButton  url={props.Url}>
                    <TwitterIcon size={38} round={true}/>
                </TwitterShareButton>
                <WhatsappShareButton  url={props.Url}>
                    <WhatsappIcon size={38} round={true}/>
                </WhatsappShareButton>
                <TelegramShareButton url={props.Url}>
                    <TelegramIcon size={38} round={true}/>
                </TelegramShareButton>
                <RedditShareButton url={props.Url}>
                    <RedditIcon size={38} round={true}/>
                </RedditShareButton>
                {/* <a
                    href={instagramURl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                    <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/800px-Instagram_logo_2022.svg.png' width={38} className='rounded-[50%]'  />
                   
                    </a> */}
                
            </div>
                </div>
               <div className='flex flex-col items-center justify-center gap-y-4'style={{fontFamily:'"Montserrat", sans-serif'}} >
                <h3 className='font-[600] text-[#5c5c5c]'> - OR -</h3>
                <h1 className='text-[#5c5c5c] font-[500] text-[16px]'>Copy Link</h1>
                <div className='flex justify-between h-[28px] w-[100%]  rounded-[5px] pl-3 pr-2 border border-gray-100 bg-[#ecebeb]'>
                <input type="text" className='w-[80%] text-[13px] text-ellipsis  text-[#404040] '  disabled
                value={props.Url}/>
                { copyUrl === true ? 
                 <button className='font-[500] text-[12px] text-[#7f7ffd]'>Copied</button>      
                : 
                <button className='font-[500] text-[12px] text-[#7f7ffd]'  onClick={handleCopy} >Copy</button>
                }
                </div>
            </div>
        </div>
    </Modal>
    </div>
  )
}

export default ShareUrl