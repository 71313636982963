import React from 'react';
import Offers_Nav from '../components/Offers_Nav';
import Hamburger from "../components/Hamburger_Nav.js"
import Footer from '../components/Footer.js';
import { Link, Navigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import axios from 'axios';
import backbutton_symbol from '../components/Images/back _symbol.png'
import { Modal } from 'antd';
import { useLocation ,useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './Styles/PackageBooking_details.css'
import {BookingActivity} from '../Utilis/BaseUrl.js'
import Success from './Images/success.png'




// Example flag icon from react-icons
import ReactFlagsSelect from "react-flags-select";
import countries from '../components/Country_List.js';
 


function ActivitiesBooking_details() {



  const [selected_date, setselected_date] = useState()
  const [no_Of_adult , setno_Of_adult] = useState()
  const [no_Of_child, setno_Of_child] = useState()
  const [itemId , setitemId] = useState()
  const [package_name , setpackage_name] = useState()
  const [package_duration , setpackage_duration] = useState()
  const booking_type = 1;
  const [user_email,setuser_email] = useState("");
  const [user_name ,setuser_name ] = useState("");
  const [user_number,setuser_number] = useState();
  const [user_nationality,setuser_nationality] = useState();
  const [user_address,setuser_address] = useState();
  const [user_passport,setuser_passport] = useState()
  const [ activity_price ,setactivity_price ] = useState()
  const navigate = useNavigate();
  const [loader , setloader] = useState(false)
  const [ alert , setalert] = useState(false)
  const [item_location , setitem_location] = useState('')
  const [location_id , setlocation_id] = useState('')


  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const data =   localStorage.getItem('myDate');



var convered;
var item_price;

useEffect(()=>{
  const sessionactivity_data = sessionStorage.getItem('activity_data')
   convered = JSON.parse(sessionactivity_data)
   setselected_date(convered.date);
   setno_Of_adult(convered.noOfAdult);
   setno_Of_child(convered.noOfChild);
   setitemId(convered.activity_id);
   setpackage_name(convered.activity_name);
   setpackage_duration(convered.activity_duration);
   setactivity_price(convered.activity_price)
   setitem_location(convered.activity_location)
   setlocation_id(convered.activity_location_id)
  

   FOnPageLoad();

  //handle screen size
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };

},[])




    //date format
    const date = new Date(selected_date);
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const dayOfWeek = weekdays[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
  
  // Format the date string
   const formattedDate = `${dayOfWeek}, ${month} ${day} ${year}`;

   //repeat printing customer details
   const [count, setCount] = useState(0);
  //  const [userData,setUserDate] =  useState([]);


   const booking_date = selected_date; 
   const booking_end_date = selected_date;


const handleChange = (event) => {
  setuser_nationality(event.target.value);
};

function FOnPageLoad(){
  const sessionUserData =localStorage.getItem('myDate');
  const Total = parseInt(convered.noOfAdult) + parseInt(convered.noOfChild);
  // console.log("---------------Total:",Total);
 
  
  setCount(Total);

  if(sessionUserData)
    {
      // console.log(JSON.parse(sessionUserData))
        // console.log(JSON.parse(sessionUserData))
        setUserDate(JSON.parse(sessionUserData))

    }
    else {
      // Data doesn't exist in session storage
      // console.log('No data found in session storage');
      navigate('/')
    }
  
}

const adult_count = no_Of_adult;
const child_count = no_Of_child;
 
item_price = (count * activity_price).toFixed(2);



const handleSubmit = async() =>{
  setloader(true)

  const sessionUserData =localStorage.getItem('myDate')
  if(sessionUserData){

    if(user_email != null && user_name != null && user_number != null && user_nationality != null &&  user_passport != null ){

      try {
        var params={itemId,user_email,user_name,user_number,user_nationality,user_address,booking_type,count,booking_date,booking_end_date,user_passport,travelers,adult_count,child_count,item_price,item_location,location_id};
        // console.log("================params:",params)
          const res = await axios.post(`${BookingActivity}`,params);
          // console.log(params)
          console.log(res)
          // console.log("first",res.data.message)
  
          if(res?.data.status === true){
              setloader(false)
              setalert(true)
              // console.log("first,",res.data)
          }
          else{
              // toast.error(res.data.message)
              // console.log(res.data.data.message)
              setloader(false)
              navigate('/')
    
          }
      } catch (error) {
          // console.log(error)
          // toast.error('error in insertion')      
          navigate('/404')
      }
    }else{
      toast.error("Please Fill The Required Fields");
      setloader(false) }

   
  }

   
  else{
    // toast.error("please login")
    navigate('/')
    setloader(false)
  }  
};

     


const [travelers,settravelers] = useState([])
const [visible,setVisible] = useState(false); 

let number=1;

const handleinputChange = (e,index)=>{
  const {name,value} = e.target;
  settravelers((prevData)=>{
    // ...prevData[index],
    // [name]:value
    const newValues = [...prevData];
    newValues[index] = {...newValues[index],[name]:value};
    return newValues;
  })

}

const [userData,setUserDate] =  useState([]);
useEffect(()=>{
  setuser_email(userData.email )
  setuser_name(userData.name)
},[userData])


//phone number style
const inputStyle = {
  width:'100%',
  height:'56px',
  borderColor:'#EAEAEA',
  fontSize:'16px',
  fontWeight:'10px',
 outline:'none',
 boxShadow:"none",
};

const smallScreenInputStyle = {
  ...inputStyle,
  fontSize: '14px',
};

//go to previous page  
const handleBackClick = () => {
  navigate(-1); // This will take the user back to the previous page
};




  return (
    <>
 <div style={{fontFamily:'Montserrat'}}>
    <Offers_Nav />
    <Hamburger callback={FOnPageLoad} />
    {/* {userData && (
    <h1>{userData.email}</h1>
    )} */}
    <section className='w-[100%] bg-[#F7F7F7] h-[auto] mb-[40px] max-sm:h-[auto]'>
    <div className="h-[auto] w-[1184px]  mx-auto  flex items-center pb-2 pt-3
                        max-xl:w-[950px]
                        max-lg:w-[700px]
                        max-md:w-[500px]
                        max-sm:px-[16px]   max-sm:w-[100%]   max-sm:h-[auto] max-sm:py-2 ">
        <div className=' w-[100%] h-[auto]  max-sm:h-[auto] '>
        { screenWidth < 640 ? "":
            <div className='flex'>
              <div  className='flex cursor-pointer' id='back-navigation' onClick={handleBackClick}>
                <span className='h-[24px] w-[24px] 
                                  max-sm:h-[13px] max-sm:w-[13px]'>
                <img  src={backbutton_symbol} className='object-cover' alt=''/>
                </span>
                <h6 className='text-[16px] font-[600] ml-1 text-[#FD8062]
                               max-sm:text-[14px] max-sm:ml-2'>Back</h6>
                </div>
            </div>
           }
            <h6 className='font-[700] text-[28px] leading-[66.88px]
                           max-sm:text-[18px] max-sm:leading-[30.88px] max-sm:mb-3'>{package_name}</h6>
            <div className='w-[auto] h-[auto] flex  max-sm:w-[auto]  '>
            {package_duration === '1'? 
              <h6 className='text-[18px] font-[400] text-[#5F5F5F] max-sm:text-[10px] '>Duration - {package_duration} hour</h6>
            :  <h6 className='text-[18px] font-[400] text-[#5F5F5F] max-sm:text-[10px] '>Duration - {package_duration} hours</h6>}
              
               <h6 className='text-[17px] font-[400] text-[#5F5F5F] max-sm:text-[10px] pl-[20px]' >{selected_date} </h6>
               <h6 className='text-[18px] font-[400] text-[#5F5F5F] max-sm:text-[10px] pl-[20px]' >{ no_Of_adult===''? '0':no_Of_adult} Adult &nbsp;  {no_Of_child===''?'0':no_Of_child} Children</h6>
            </div>

        </div>

        </div>
    </section>

  
 
    <section className='mb-[40px]'>
    <div className="  h-[auto] w-[1184px]  mx-auto grid grid-cols-[677px,475px]  gap-x-[32px]  
                        max-xl:w-[950px]  max-xl:grid-cols-[572px,358px] max-xl:gap-x-[20px]
                        max-lg:w-[700px]  max-lg:grid-cols-1
                        max-md:w-[500px]     
                        max-sm:px-[16px]  max-sm:w-[100%]">
       <div className=''>
                 <h3 className='text-[20px] font-[600] text-[#454545] mb-[20px]
                               max-sm:text-[16px]'>Add Contact Details</h3>
                <div className='h-auto '>
                                <form method='' action=''>
                            <div className='h-[92px]  mb-[24px]  ' >
                                        <h5 className='font-[600] text-[16px] leading-[19.5px] mb-3
                                                       max-sm:text-[14px]'>E-mail<span className='text-[#FF5050]'> *</span></h5>
                                        <div >                                    
                                            <input type="text"  className='h-[56px] w-[100%]  border border-[#EAEAEA] rounded-[8px] pl-2 max-sm:text-[14px]' autoComplete='off' placeholder='stephen@gmail.com' required
                                             value={user_email} 
                                             onChange={(e) => setuser_email(e.target.value)}/>
                                        </div>
                            </div>
                            <div className='h-[92px]  mb-[24px]  ' >
                                        <h5 className='font-[600] text-[16px] leading-[19.5px] mb-3
                                                       max-sm:text-[14px]'>Name<span className='text-[#FF5050]'> *</span></h5>
                                        <div >                                    
                                            <input type="text"  className='h-[56px] w-[100%]  border border-[#EAEAEA] rounded-[8px] pl-2 max-sm:text-[14px]' autoComplete='off' placeholder='Enter Name' required
                                             value={user_name} 
                                             onChange={(e) => setuser_name(e.target.value)}/>
                                        </div>
                            </div>
                            <div className='h-[92px]  mb-[24px]  ' >
                                        <h5 className='font-[600] text-[16px] leading-[19.5px] mb-3
                                                       max-sm:text-[14px]'>Contact No<span className='text-[#FF5050]'> *</span></h5>
                                        <div  >                                    
                                            {/* <input type="text"  className='h-[56px] w-[100%]  border border-[#EAEAEA] rounded-[8px] pl-2' autoComplete='off'
                                             value={user_number} 
                                             onChange={(e) => setuser_number(e.target.value)}/> */}
                                             <PhoneInput
                                                country={'us'}
                                                value={user_number}
                                                onChange={user_number => setuser_number(user_number)}

                                                inputStyle={
                                                  window.innerWidth <= 640 ? smallScreenInputStyle : inputStyle
                                                }
                                              
                                                buttonStyle={{
                                                  position: 'absolute',
                                                  top: '50%',
                                                  transform: 'translateY(-50%)',
                                                  left: '1.5px',
                                                  border: '2px solid transparent',
                                                  outline: 'none',
                                                  cursor: 'pointer',
                                                  backgroundColor:'transparent'
                                              }}
                                                inputClass="red-border" // Custom class for red border on focus
                                                buttonClass="red-border"
                                              />
                                        </div>
                            </div>
                            <div className='h-[92px]  mb-[24px]  ' >
                                        <h5 className='font-[600] text-[16px] leading-[19.5px] mb-3 max-sm:text-[14px]'>Country<span className='text-[#FF5050]'> *</span></h5>
                                        <div >                                    
                                            {/* <input type="text"  className='h-[56px] w-[100%]  border border-[#EAEAEA] rounded-[8px] pl-2' autoComplete='off'
                                              value={user_nationality} 
                                              onChange={(e) => setuser_nationality(e.target.value)}/> */}
                                           
                                           <div>
                                                {/* <label>Select a country:</label> */}
                                                <select value={user_nationality} onChange={handleChange} className='h-[56px] w-[100%]  border border-[#EAEAEA] rounded-[8px] pl-2 max-sm:text-[14px] bg-[white] ' required>
                                                    <option value="">Choose your country</option>
                                                    {countries.map((country, index) => (
                                                        <option key={index} value={country}>{country}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                            </div>
                            <div className='h-[auto]  mb-[18px]  ' >
                                        <h5 className='font-[600] text-[16px] leading-[19.5px] mb-3 max-sm:text-[14px]'>Address:</h5>
                                        <div >                                    
                                           <textarea className='h-[206px] w-[100%]  border border-[#EAEAEA] rounded-[8px] pl-2 pt-3 resize-none max-sm:text-[14px]'  rows={4} cols={50} placeholder='Add your address here'
                                             value={user_address} 
                                             onChange={(e) => setuser_address(e.target.value)} />
                                        </div>
                            </div>
                            <div className='h-[92px]    ' >
                                        <h5 className='font-[600] text-[16px] leading-[19.5px] mb-3 max-sm:text-[14px]'>Passport Details<span className='text-[#FF5050]'> *</span></h5>
                                        <div >                                    
                                            <input type="text"  className='h-[56px] w-[100%]  border border-[#EAEAEA] rounded-[8px] pl-2 max-sm:text-[14px]' autoComplete='off' placeholder='Your Passport Number' required
                                               value={user_passport} 
                                               onChange={(e) => setuser_passport(e.target.value)} />
                                        </div>
                            </div>
                           
                            
                                </form>
                          
                </div>
        </div>

        
        { screenWidth < 1024 ? "":
       <div className=' border border-[#EAEAEA] px-[24px] h-[235px] flex items-center justify-center'>
            <div className='h-[185px] w-[100%]  relative max-xl:h-[190px]'>
                    <div className='h-[101px] w-[100%] max-xl:h-[auto] '>
                            <h3 className='font-[600] text-[20px] text-[#454545] leading-[24.38px]
                                            max-xl:text-[16px]'>Total Amount ( Adult - { no_Of_adult===''? '0':no_Of_adult},&nbsp;Children -  {no_Of_child===''?'0':no_Of_child } ) </h3>
                            <h2 className='font-[700] text-[28px] leading-[60.13px]
                                           max-xl:text-[26px]'> &#36;  {item_price}</h2>
                            <h6 className='font-[400] text-[16px] leading-[14.88px]
                                           max-xl:text-[14px]'>inclusive of taxes</h6>
                    </div>
                    {loader === false?
                       <button className='h-[52px] w-[100%] bg-[#DE6A29] text-[white] absolute bottom-0 rounded-[4px]  ' id='proceed-to-check' onClick={()=>{handleSubmit()}} >Proceed To Check-out</button>
                       :
                      //  loader----------------------
                       <button className='h-[52px] w-[100%] bg-[#DE6A29] text-[white] absolute bottom-0 rounded-[4px] ' id='loading-button'>
                            <div role="status">
                            <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-orange-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>


                        </button>
                      //  loader---------------------- end
                    }
            </div>
       </div>
       }

      </div>
    </section>
    <section className='mb-[80px] max-lg:mb-4'>
             <div className=" h-[auto] w-[1184px]  mx-auto    
                        max-xl:w-[950px]
                        max-lg:w-[700px]
                        max-md:w-[500px]
                        max-sm:px-[16px]    max-sm:w-[100%]">
                            <div className='h-[276px]'>
                                    <h4 className='font-[600] text-[20px] text-[#454545] leading-[24.38px]
                                                   max-sm:text-[16px]'>Add Traveller Details</h4>
                                    <div className='h-[228px] bg-[#EBFFFB] w-[100%] mt-[23px] pt-[24px]'>
                                            <div className='w-[311px] h-[auto]  ml-[24px] 
                                                            max-sm:ml-4 max-sm:w-[90%]'>
                                           <button className='font-[600] text-[16px] bg-[#ffffff] w-full h-[43px] rounded-[16px] border  border-[black]
                                                              max-sm:text-[14px]' id='traveller-name' onClick={()=>{setVisible(true)}} >
                                                              {travelers.length === 0 ? 'Add Traveller details':`${travelers[0]?.first_name}  ${(travelers?.length - 1 === 0 ? "": `+ ${travelers?.length - 1}`)}`}
                                                                </button> 
                                            {/* <button className='font-[600] text-[16px] bg-[white] w-full h-[43px] rounded-[16px] border mb-[10px]  border-[black]'>Add Traveller 2</button> */}
                                            </div>
                                    </div>
                            </div>
            </div>
    </section>
 
    { screenWidth < 1024 ?
       <div className='  border border-[#EAEAEA] px-[24px] h-[235px] flex items-center justify-center  
                        mx-auto 
                        max-lg:w-[700px]  max-lg:mb-[80px]
                        max-md:w-[500px]
                        max-sm:mx-[16px]    max-sm:w-[100%]'>
       <div className='h-[185px] w-[100%]  relative '>
               <div className='h-[101px] w-[100%]'>
                       <h3 className='font-[600] text-[20px] text-[#454545] leading-[24.38px]
                                       max-xl:text-[15px]
                                       max-sm:text-[14px]'>Total Amount ( Adult - { no_Of_adult===''? '0':no_Of_adult},&nbsp;Children -  {no_Of_child===''?'0':no_Of_child } ) </h3>
                       <h2 className='font-[700] text-[28px] leading-[60.13px]
                                      max-xl:text-[26px]'> &#36; {item_price}</h2>
                       <h6 className='font-[400] text-[16px] leading-[14.88px]
                                      max-xl:text-[14px]'>inclusive of taxes</h6>
               </div>
               {loader === false?
                  <button className='h-[52px] w-[100%] bg-[#DE6A29] text-[white] absolute bottom-0 rounded-[4px] ' onClick={()=>{handleSubmit()}} >Proceed To Check-out</button>
                  :
                 //  loader----------------------
                  <button className='h-[52px] w-[100%] bg-[#DE6A29] text-[white] absolute bottom-0 rounded-[4px] ' id='loading-button' >
                       <div role="status">
                       <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-orange-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                         <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                       </svg>
                       <span className="sr-only">Loading...</span>
                     </div>


                   </button>
                 //  loader---------------------- end
               }
       </div>
  </div>
   :""}
   



    <Footer/>
    <Modal onCancel={()=>{setVisible(false)}}
        footer={null}
        visible={visible}
        width={'775px'}
        >

            <div className='w-[711px] h-[61px]  m-[10px]'>
                <h3 className='font-[700] text-[24px] mb-[12px] '>Traveller Details</h3>
                <h6 className='font-[600] text-[18px] '>( { no_Of_adult===''? '0':no_Of_adult} Adult &nbsp;,  {no_Of_child===''?'0':no_Of_child} Children )</h6>
            </div>
            <hr className='w-[711px] h-[1px] ml-[10px] mt-[24px]
                           max-sm:mx-[10px] max-sm:w-[100%]'></hr>
            <div className='h-[auto]'>
            <div>
      {Array.from({ length: count }).map((_, index) => (
        <>
      
     <form  >
     <div className='w-[711px] h-[256px] max-md:h-[400px]  ml-[10px] mt-[40px] max-md:w-[100%] max-md:mb-[80px] max-sm:mx-[1px] max-sm:w-[100%]'>
                <h4 className="text-[#646464] text-[20px] font-[600] leading-[16px]">Traveler {number++}</h4>
                <div className=' h-[216px] mt-[24px] '>
                <div className='h-[92px]  flex gap-x-4  max-md:flex-col max-md:h-[auto] max-md:gap-y-4'>
                <div className=' w-[335px] h-[100%] max-md:w-[100%]  '>
                                 <h4 className='font-[600] text-[16px]'>First Name</h4>
                                 <div className='w-[100%] h-[56px] border border-[#EAEAEA] mt-[18px] rounded-[8px]'>
                                    <input type="text" className='w-full h-full pl-[16px] text-[16px] rounded-[8px]' name={`first_name`} autoComplete='off'
                                     value={travelers.first_name} 
                                     onChange={(e) => handleinputChange(e,index)}/>
                                 </div>
                            </div>
                            <div className=' w-[335px] h-[100%]  max-md:w-[100%] '>
                            <h4 className='font-[600] text-[16px]'>Last Name</h4>
                            <div className='w-[100%] h-[56px] border border-[#EAEAEA] mt-[18px] rounded-[8px]'>
                                    <input type="text" className='w-full h-full pl-[16px] text-[16px] rounded-[8px]' name={`last_name`} autoComplete='off'
                                     value={travelers.last_name} 
                                     onChange={(e) => handleinputChange(e,index)}/>
                                 </div>
                            </div>

                    </div>
                    <div className='h-[92px]  flex gap-x-4  max-md:flex-col max-md:h-[auto] max-md:gap-y-4  mt-3'>
                    <div className='w-[335px] h-[100%] max-md:w-[100%]'>
                                 <h4 className='font-[600] text-[16px]'>Gender</h4>
                                 <div className='w-[100%] h-[56px] border border-[#EAEAEA] mt-[18px] rounded-[8px]'>
                                      <select className='w-full h-full pl-[16px] text-[16px] rounded-[8px] bg-[white]' name={`gender`}  value={travelers.gender} 
                                     onChange={(e) => handleinputChange(e,index)}>
                                          <option selected disabled value>Choose gender</option>
                                          <option value="Male">Male</option>
                                          <option value="female">Female</option>
                                          <option value="Others">others</option>
                                        
                                        </select>

                                    {/* <input type="text" className='w-full h-full pl-[16px] text-[16px] rounded-[8px]' name={`gender`}
                                     value={travelers.gender} 
                                     onChange={(e) => handleinputChange(e,index)}/> */}
                                 </div>
                            </div>
                            <div className=' w-[335px] h-[100%] max-md:w-[100%]'>
                            <h4 className='font-[600] text-[16px]'>Date Of Birth</h4>
                            <div className='w-[100%] h-[56px] border border-[#EAEAEA] mt-[18px] rounded-[8px]'>
                                    <input type="date" className='w-full h-full px-[16px] text-[16px] rounded-[8px] bg-[white]' name={`date_of_birth`}
                                     value={travelers.date_of_birth} 
                                     onChange={(e) => handleinputChange(e,index)}/>
                                 </div>
                            </div>
                       

                    </div>
                </div>
               
            </div>
            </form>
            </>
             ))}
                        </div>

                        <div className='h-[52px]  w-[100%] ml-[10px]  mt-[40px] flex justify-end max-sm:w-[100%] max-sm:mx-[1px] '>
                        <div className=' w-[261px] flex justify-between'>
                            <button className='w-[87px] h-[100%] border border-[#EBEBEB] rounded-[4px]' id='cancel-button' onClick={()=>{settravelers([]);setVisible(false)}}>Cancel</button>
                            <button className='w-[150px] h-[100%] bg-[#DE6A29] rounded-[4px] text-[white]' id='save-button' onClick={()=>setVisible(false)}>Save</button>

                        </div>
                    </div>
            </div>
        </Modal>

        {/* activity booking alert  */}
        <Modal onCancel={()=>{ setalert(false); navigate("/");}}
        footer={null}
        visible={alert}
        width={'544px'}
        >
          <div className='h-[200px]'>
            <div className='flex justify-center items-center flex-col mt-4'>
              <img  src={Success} alt=''/>
              <h1 className='text-[24px] font-[600] mt-3'>Booking Successful</h1>
              <button className=' text-[16px] font-[600] text-[white] px-5 py-2 rounded-[4px] bg-[#F77B5D] mt-3' id='back-to-home' onClick={()=>{ setalert(false); navigate("/");}}>Back to home page</button>
            </div>
          </div>

        </Modal>
    </div>

    </>
  )
}

export default ActivitiesBooking_details