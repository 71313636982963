// import React, { useEffect, useRef } from 'react';

// const AutoScrollCarousel = () => {
//   const scrollRef = useRef(null);

//   useEffect(() => {
//     const scroll = () => {
//       if (scrollRef.current) {
//         const isEnd = scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >= scrollRef.current.scrollWidth;
//         if (isEnd) {
//           scrollRef.current.scrollLeft = 0;
//         } else {
//           scrollRef.current.scrollLeft += 1; // Adjust the scroll speed here
//         }
//       }
//     };
//     const intervalId = setInterval(scroll, 20); // Adjust the interval speed here

//     return () => clearInterval(intervalId); // Clean up the interval on component unmount
//   }, []);

//   return (
//     <div className='overflow-x-auto flex scrollbar-hide max-w-[1184px] mx-auto' ref={scrollRef}>
//       <div className='flex space-x-4'>
//         {/* Replace these with your actual carousel items */}
//         <div className='w-48 h-48 bg-red-500'>Item 1</div>
//         <div className='w-48 h-48 bg-blue-500'>Item 2</div>
//         <div className='w-48 h-48 bg-green-500'>Item 3</div>
//         <div className='w-48 h-48 bg-yellow-500'>Item 4</div>
//         <div className='w-48 h-48 bg-purple-500'>Item 5</div>
        
//       </div>
//     </div>
//   );
// };

// export default AutoScrollCarousel;



// import React, { useEffect, useState } from 'react'
// //date picker
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { addDays, eachDayOfInterval, isValid ,parse,format} from 'date-fns';
// import './Styles/DatePicker.css'
// import { Modal } from 'antd';


// function PackageBooking() {

//    //date picker
//      const [startDate, setStartDate] = useState(null);
//      const [highlightedDates, setHighlightedDates] = useState([]);
//      const [range, setRange] = useState(''); // Default to 7 days
//      const [visible , setvisible] = useState(false)
//      const [booking_date, setbooking_date] = useState('');
//      const [booking_end_date, setbooking_end_date] = useState('');
//      const [login ,setlogin] =  useState(false);
//      const [userData,setUserDate] =  useState([])

//     useEffect(() => {
//       if (isValid(startDate)) {
//           const endDate = addDays(startDate, range-1); // Highlight the next 6 days
//           const dates = eachDayOfInterval({ start: startDate, end: endDate });
//           setHighlightedDates(dates);
//           setbooking_date(formatDate(startDate));
//           setbooking_end_date(formatDate(endDate));

//       } else {
//           setHighlightedDates([]);
//       }
//   }, [startDate]);

//   const handleDateChange = (date) => {
//       if (isValid(date)) {
//           setStartDate(date);
//       } else {
//           setStartDate(null);
//       }
//   };

//   const formatDate = (date) => {
//     return date.toLocaleDateString('en-GB');
// };

// function ClearCalender() {
//   setvisible(false);
//   setbooking_date('');
//   setbooking_end_date('');
//   setStartDate('')
//   setHighlightedDates('')

// }
//     //disable calender 
//     const disabledStartDate = parse('26/09/2024', 'dd/MM/yyyy', new Date());
//     const disabledEndDate = parse('30/09/2024', 'dd/MM/yyyy', new Date());

//     // Function to generate an array of dates to disable
//     const generateDisabledDates = (startDate, endDate) => {
//       const dates = [];
//       let currentDate = startDate;
//       while (currentDate <= endDate) {
//         dates.push(new Date(currentDate));
//         currentDate = addDays(currentDate, 1);
//       }
//       return dates;
//     };

//     const disabledDates = generateDisabledDates(disabledStartDate, disabledEndDate);

//     const isDateDisabled = (date) => {
//       return disabledDates.some(disabledDate =>
//         date >= disabledDate && date <= addDays(disabledDate, 1)
//       );
//     };

//   return (
//     <div>
//             <div>
//                 <div className='font-montserrat text-xs font-medium text-[#9A9A9A] mb-[16px]
//                                   max-lg:mb-2'>Travellers (No .of Childern) </div>
//                 <div className="relative flex items-center w-full  mb-[16px] max-md:mb-2 max-md:h-[40px] ">
//                       <button type="button" id="decrement-button-1" data-input-counter-decrement="quantity-input" className=" dark:bg-white-700 dark:hover:bg-white-600 dark:border-white-600 hover:bg-white-200 border border-white-300 rounded-s-lg p-3 h-9  bg-[white]" >
//                         <svg className="w-2 h-1 text-gray-900 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
//                           <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h16" />
//                         </svg>
//                       </button>
//                       <input type="text" id="quantity-input" data-input-counter aria-describedby="helper-text-explanation" className=" border-x-0 border-whute-300 h-9 text-center text-white-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-gray-400 dark:text-grey dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-[white] border" placeholder={0} required />
//                       <button type="button" id="increment-button-2" data-input-counter-increment="quantity-input" className="dark:bg-white-700 dark:hover:bg-white-600 dark:border-white-600 hover:bg-white-200 border border-white-300 rounded-e-lg p-3 h-9 bg-[white]" >
//                         <svg className="w-2 h-2 mt-[-2px] text-gray-900 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
//                           <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 1v16M1 9h16" />
//                         </svg>
//                       </button>
//                     </div>
                 
                
//                 <button className='bg-black text-white w-full p-1.5 rounded-3xl font-montserrat text-16 font-semibold leading-17 text-center' id='book-now' >Book Now</button>
                
//               </div>
          
//         <>
//       <Modal onCancel={()=>ClearCalender()}
//                  footer={null}
//                  visible={visible}
//                  width={280}
//                 >
//                     <div className='flex justify-center items-center mt-4'>

//                         <DatePicker
//                 selected={startDate}
//                 onChange={handleDateChange}
//                 highlightDates={highlightedDates}
//                 inline
//                 minDate={new Date()}
//                 excludeDates={disabledDates} // Disable the date range
//                 filterDate={date => !isDateDisabled(date)}
//                 />
//                 </div>
//                 <div className='flex items-center justify-between mt-2.5 mb-0'>
//                   <button className='border bo rounded-[4px] px-3 py-2  bg-[#ffffff]' onClick={ClearCalender} id='cancel' >Cancel</button>
//                   <button className='border rounded-[4px] px-[30px] py-2  bg-[#DE6A29] text-[WHITE] ' onClick={()=> setvisible(false)} id='select-date'>Select</button>
//                 </div>

//             </Modal>
//             </>
             
//     </div>
//   )
// }

// export default PackageBooking



//language translate

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [textToTranslate, setTextToTranslate] = useState('Hello, world!'); // Original text
  const [translatedText, setTranslatedText] = useState(''); // Translated text
  const [loading, setLoading] = useState(false);

  // Function to translate text using LibreTranslate
  const translateText = async (text) => {
    setLoading(true);
    try {
      // Call the LibreTranslate API
      const response = await axios.post('https://libretranslate.de/translate', {
        q: text,          // Text to translate
        source: 'en',     // Source language (English)
        target: 'zh',     // Target language (Chinese)
        format: 'text',
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      // Update the translated text state
      setTranslatedText(response.data.translatedText);
    } catch (error) {
      console.error('Translation error:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Translate the text when the component mounts
    translateText(textToTranslate);
  }, [textToTranslate]);

  return (
    <div>
      <h1>LibreTranslate in React.js</h1>
      <div>
        <label>
          Enter text to translate:
          <input
            type="text"
            value={textToTranslate}
            onChange={(e) => setTextToTranslate(e.target.value)}
          />
        </label>
      </div>
      <div>
        <button onClick={() => translateText(textToTranslate)}>
          Translate
        </button>
      </div>
      <div>
        {loading ? (
          <p>Translating...</p>
        ) : (
          <p>Translated Text: {translatedText}</p>
        )}
      </div>
    </div>
  );
}

export default App;
