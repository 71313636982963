import React from 'react'
// import BoatVideo from './Images/boatvideo.mp4'
import Hamburger from "../components/Hamburger_Nav.js"
import axios from 'axios';
import OffersBar from "../components/Offers_Nav";
import Search from '../components/Search';
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';
import "./Styles/Activities.css"
import { useState,useEffect } from 'react';
import {viewAllActivity} from '../Utilis/BaseUrl.js'
import Logo from './Images/loaderLogo.png'
import Whatsapp from '../components/Whatsapp.js';
import slugify from 'slugify';
import skeletonImage from './Images/sheleton loader image.png'



function Activities() {

    const navigate = useNavigate();
    const [loaderLoad , setloaderLoad] = useState(true)


    
    const [getActivities ,setActivities] = useState([])
    const ListAllActivities = async ()=>{
      try{
          const response= await axios.get(`${viewAllActivity}`);
          if(response){
              setActivities(response.data.data);
              setloaderLoad(false)

          }
      }catch(error){
        //   console.log(error)
        navigate('/404')
      }
    }

    useEffect(()=>{
      ListAllActivities();
    },[]);

    const handleActivities = (id, activity_name)=>{
        sessionStorage.setItem('Activity_id',id);
        const slug = slugify(activity_name)
        navigate(`/activitybooking/${slug}/${id}`)
      }

            //activity image loader
    const [ActivityIamgeLoad, setActivityIamgeLoad] = useState({});     
     const handleactivityimage = (id) => {
     setActivityIamgeLoad((prevStates) => ({
        ...prevStates,
        [id]: false,
     }));
    };

    return (
        <div>

      {/* loader------ */}
   {loaderLoad === true ? 
    <div className='flex items-center justify-center flex-col  h-[100vh]   '>
      <img className='h-[60px]' src={Logo} alt='' />
      <div className="dots mt-3"></div>
     </div>
     :
     <>
            <OffersBar />
            <Hamburger/>
                <div>
                    <div className='relative flex'>
                        <div className='absolute top-[150px] left-1/2 -translate-x-1/2 z-30 flex flex-col 
                                        max-sm:top-[100px]'>
                            <div
                                className="text-[48px] text-white font-bold leading-[30px] text-center text-nowrap 
                                max-md:text-[38px]  max-md:h-[40px]
                                max-sm:text-[20px]  max-sm:h-[30px] ">
                                Discover Paradise: 
                            </div>
                            <div
                                className="text-[18px] font-normal leading-9 text-center text-white  text-nowrap  mt-[30px] max-sm:mt-2 
                                 max-md:text-[16px] max-md:h-[40px]  max-md:mb-[-10px]
                                 max-sm:text-[10px] max-sm:h-[30px] max-sm:mb-[-25px] max-sm:text-nowrap">
                                Embark on unforgettable journeys with trippers
                            </div>
                           
                            {/* <Search/> */}

                           
                        </div>
                        
                        <video autoplay="{true}" loop muted className="absolute flex jusitify-center items-center z-10 w-full h-[450px] object-cover filter contrast-75
                                                                       max-sm:h-[300px]">
                            <source src='https://d1ikk3qahjralr.cloudfront.net/trippers-media/boatvideo.4c55c77a580203389153.mp4' type='video/mp4' />
                            
                        </video>
                        <div className="absolute flex jusitify-center items-center z-10 w-full h-[450px] max-sm:h-[300px] bg-black bg-opacity-50 pointer-events-none"></div>
                       
                        <div className='mt-[520px] mx-auto h-auto max-w-[1184px] mb-[100px] 
                                        max-xl:w-[980px] 
                                       max-lg:w-[700px] 
                                        max-md:w-[550px]  
                                       max-sm:w-[100%] max-sm:px-[16px] max-sm:mt-[360px]'>
                            <div className='font-bold leading-66.88 text-2xl
                                              max-md:text-[20px] 
                                              max-sm:text-[18px]'>Unforgettable Activities Await</div>
                            <div id="main" class="grid gap-6 justify-evenly mt-6 grid-cols-4
                                         max-xl:grid-cols-4  
                                         max-lg:grid-cols-3  
                                         max-md:grid-cols-2    
                                         max-sm:grid-cols-1 ">
                              {getActivities?.map((a)=>(    
                                  <>
                                {a?.is_active === true ?                           
                                    <div className="relative activity-card cursor-pointer" key={a.id} onClick={()=>handleActivities(a.id , a.activity_name)}>
                                         {ActivityIamgeLoad[a.id] !== false  && (<img className="activity-card-img rounded-lg object-cover  animate-pulse" src={skeletonImage} alt='' />)}                                     
                                        <img className={`activity-card-img rounded-lg object-cover  ${a.public_enabled === true ?'grayscale-0':'grayscale'} `}src={a.activity_image[0]} onLoad={() => handleactivityimage(a.id)} style={{ display: ActivityIamgeLoad[a.id] !== false ? 'none' : 'block' }}  />
                                        <div className='activity-card-name text-[15px] leading-[27px] mt-2 h-[28px] line-clamp-1 
                                                        max-lg:text-[15px] max-lg:leading-[25px] max-lg:h-[26px] 
                                                        max-md:mb-[-10px]
                                                        max-sm:h-[27px]  max-sm:line-clamp-1  max-sm:mb-[1px] '>{a.activity_name}</div>
                                        {a.activity_duration === '1'?
                                           <div className='mt-2 activity-card-HRS 
                                           max-lg:mt-1 max-lg:text-[10px]
                                           max-sm:mt-3 '>{a.activity_duration} Hour</div>
                                           :   <div className='mt-2 activity-card-HRS 
                                           max-lg:mt-1 max-lg:text-[10px]
                                           max-sm:mt-3 '>{a.activity_duration} Hours</div>
                                        }                
                                     
                                        <div className='activity-card-rate text-[16px] leading-[34px] 
                                                        max-lg:mt-0 max-lg:text-[13px]
                                                        max-sm:mt-[0px] '>
                                                            {a?.location ? 
                                                            <>
                                                            {a?.activity_offer <= 0 ? (
                                                    <span>${a?.location.reduce((min, loc) => {
                                                        const locOffer = parseFloat(loc.loc_price);
                                                        return locOffer < min ? locOffer : min;
                                                    }, parseFloat(a?.location[0]?.loc_price))}</span>
                                                ) : (
                                                    <>
                                                    <span className='font-[400]'>
                                                        <del>${a?.location[0]?.loc_price}</del>&nbsp;&nbsp;
                                                        <strong>
                                                        ${a?.location.reduce((min, loc) => {
                                                            const locOffer = parseFloat(loc.loc_offer);
                                                            return locOffer < min ? locOffer : min;
                                                        }, parseFloat(a?.location[0]?.loc_offer))}
                                                        </strong>
                                                    </span>

                                                    <div
                                                        className={`w-[50px] h-[30px] ${
                                                        a.public_enabled === true ? 'bg-[#f97316]' : 'bg-[#3490dc] grayscale'
                                                        } transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]`}
                                                    >
                                                        {a.activity_offer}%
                                                    </div>
                                                    </>

                                                )} 
                                                </>
                                                :""}
                                                <span className='activity-card-span text-[14px] max:lg-text-[12px]'> per person</span></div>
                                    </div>
                                    :""}
                                    </>
                                ))}
                            </div> 
                        </div>
                        <Whatsapp/>
                    </div>
                </div>
            <Footer />
            </> }       
        </div>
    )
}

export default  Activities;