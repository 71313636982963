import React from 'react';
import Hamburger from "../components/Hamburger_Nav.js"
import Offers_Nav from '../components/Offers_Nav';
import './Styles/Home.css';
// import Intro_video from './Images/intro_video.mp4';
import Island from './Images/island.png';
import Tree from './Images/tree.png';
import Underwater from './Images/underwater.png';
import Swimmingpool from './Images/swimmingpool.png';
import Boat from './Images/boat.png';
import Kayaking from './Images/kayaking.png';
import Parachute from './Images/parachute.png';
import Underwaterswimming from './Images/underwaterswimming.png';
import Beach from './Images/beach.png';
import People from './Images/people.png';
import Tourist from './Images/tourist.png';
// import Beach_video from './Images/beach_video.mp4';
import Search from '../components/Search';
import Offers_and_Deals from '../components/Offers_and_Deals';


import { Link,useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { useState,useEffect,useRef } from 'react';
import axios from 'axios';
import {viewAllPackages,viewAllActivity ,CarouselView} from '../Utilis/BaseUrl.js'
import Logo from './Images/loaderLogo.png'
import Whatsapp from '../components/Whatsapp.js';
import Review from './Images/Review.png'
import Profile1 from './Images/profile1.png'
import Profile2 from './Images/profile2.png'
import slugify from 'slugify';
import RightArrow from './Images/right arrow.svg'
import LeftArrow from './Images/left arrow.svg'
import carouselSkeletonImage from './Images/carousel skeleton image.jpg'
import Blog from '../components/Blog.js';
import profile3 from './Images/profile3.png'



function Home() {

    const navigate = useNavigate();
    const [loaderLoad , setloaderLoad] = useState(true)

//Scrolling button
const scrollRef1 = useRef(null);
const scrollRef2 = useRef(null);


const handleScrollLeft = (container) => {
  const scrollRef = container === 1 ? scrollRef1 : scrollRef2;
  if (scrollRef.current) {
    scrollRef.current.scrollLeft -= 100;
  }
};

const handleScrollRight = (container) => {
  const scrollRef = container === 1 ? scrollRef1 : scrollRef2;
  if (scrollRef.current) {
    scrollRef.current.scrollLeft += 100;
  }
};


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    
      }, []);

      
    //   list all packages
      const [getallPackages,setallAPackages] = useState([])
      const ListAllPackages = async ()=>{
        try{

            const response = await axios.get(`${viewAllPackages}`);
            if(response.status === 200 && response.data.status === true  ){
            // console.log("res",response.data.status)

            if(response){
                setallAPackages(response.data.data);                               
            }
        }else{
            navigate('/ErrorPage')
        }
        }catch(error){
            // console.log(error)
        }
      }

      //   all activities 
      const [getActivities ,setActivities] = useState([])
      const ListAllActivities = async ()=>{
        try{
            const response= await axios.get(`${viewAllActivity}`);
            if(response.status === 200  && response.data.status === true ){
            if(response){
                setActivities(response.data.data);
                setloaderLoad(false)
            }}else{
                navigate('/ErrorPage')
            }
        }catch(error){
            // console.log(error)
        }
      }

          //   listing carousel
          const [getAllCarousel,setAllCarousel] = useState([])
          const ListAllCarousel = async ()=>{
            try{
    
                const response = await axios.post(`${CarouselView}`);
                if(response.status === 200  && response.data.status === true ){
                if(response){
                    setAllCarousel(response.data.data);  
                    // console.log("response.data.data",response.data.data)                             
                }}else{
                    navigate('/ErrorPage')
                }
            }catch(error){
                // console.log(error)
            }
          }



      useEffect(()=>{
        ListAllPackages();
        ListAllActivities();
        ListAllCarousel();
      },[]);

      const handlepackage = (id ,package_name)=>{
        sessionStorage.setItem('Package_id',id);
        const slug = slugify(package_name)
         navigate (`/packagebooking/${slug}/${id}`)
        // console.log(getallPackages)
        // const selected_item = getallPackages.find((item)=>item?.id===id)
        // navigate("/packagebooking",{ 
        //     state: {
        //         data:selected_item
        // },
        // })
      };

      const handleActivities = (id , activity_name)=>{
        sessionStorage.setItem('Activity_id',id);
        const slug = slugify(activity_name)
        navigate(`/activitybooking/${slug}/${id}`)

      }

      //combining scrollRef and AutoScroll (beacuse both ref didn't work like this "ref={(scrollRef,AutoScroll)}" .so we combine it into a common ref and call it )
      const setRefs = (element) => {
        scrollRef.current = element;
        AutoScroll.current = element;
      };
  
      //first carousel


        const scrollRef = useRef(null);
        const handleScrollLeftFirst = () => {
            if (scrollRef.current) {
                const slideWidth = scrollRef.current.children[0].clientWidth;
                scrollRef.current.scrollLeft -= slideWidth;
            }
        };
        
        const handleScrollRightFirst = () => {
            if (scrollRef.current) {
                const slideWidth = scrollRef.current.children[0].clientWidth;
                scrollRef.current.scrollLeft += slideWidth;
            }
        };

      //auto scroll for carousel
      const AutoScroll = useRef(null);
      useEffect(() => {
        const scroll = () => {
            if (AutoScroll.current) {
                const maxScrollLeft = AutoScroll.current.scrollWidth - AutoScroll.current.clientWidth;
                if (AutoScroll.current.scrollLeft >= maxScrollLeft) {
                  AutoScroll.current.scrollLeft = 0;
                } else {
                  AutoScroll.current.scrollLeft += AutoScroll.current.clientWidth; // Scroll by the width of one slide
                }
              }
            };
            const intervalId = setInterval(scroll, 10000); // Change slide every 20 seconds
        
            return () => clearInterval(intervalId); // Clean up the interval on component unmount
          }, []);

     
          //carousel image loader
          const [loadingStates, setLoadingStates] = useState({});

          const handleImageLoad = (id) => {
            setLoadingStates((prevStates) => ({
              ...prevStates,
              [id]: false,
            }));
          };

          //package image loader
          const [packageImageLoad, setpackageImageLoad] = useState({});
           
          const handlepackageimage = (id) => {
            setpackageImageLoad((prevStates) => ({
              ...prevStates,
              [id]: false,
            }));
          };

        //activity image loader
          const [ActivityIamgeLoad, setActivityIamgeLoad] = useState({});
           
          const handleactivityimage = (id) => {
            setActivityIamgeLoad((prevStates) => ({
              ...prevStates,
              [id]: false,
            }));
          };


    const packageOfferFilter = getallPackages.filter((offerFilter)=>
        offerFilter.package_offer >'0' && offerFilter.package_offer !='' && offerFilter.public_enabled ==true  && offerFilter.is_active == true 

      );
    //   console.log("offeer",packageOfferFilter)

    const ActivityOfferFilter = getActivities.filter((getActivities) =>
        getActivities.activity_offer > '0' && getActivities.activity_offer !='' && getActivities.public_enabled ==true  && getActivities.is_active == true 

        ); 
        // console.log("first",ActivityOfferFilter.length)

    function navigatePage(page){
        window.open(`${page}`, '_blank');
    }    


    return (
        <div>
    
{/* loader------ */}
   {loaderLoad === true ? 
    <div className='flex items-center justify-center flex-col h-[100vh]  '>
      <img className='h-[60px]' src={Logo} alt='' />
      <div className="dots mt-3"></div>
     </div>
     :
     <>
            <Offers_Nav />
         
            <Hamburger />
      
           
            <div className='body-container-row relative'>
               

                    {/* carousel */}
                            
                               {getAllCarousel.length > 0 ? (
                                   <div className='relative max-w-[1184px] mx-auto mt-2 max-h-[380px]'>
                                        <div className='overflow-x-scroll flex scrollbar-hide max-w-[1184px] mx-auto ' ref={setRefs} >
                                            {getAllCarousel?.map((l) => (
                                                <>
                                                 {loadingStates[l.id] !== false && ( 
                                                //   <div className='max-h-[380px] h-[200px] w-[100%] rounded-[10px] bg-[#cdcdcd] animate-pulse max-sm:h-[100px]'></div>
                                                <img className='max-h-[380px] w-[100%] rounded-[10px] animate-pulse ' src={carouselSkeletonImage} alt={''} />
                                                )}
                                                
                                                 <img key={l.id} className='max-h-[380px] w-[100%] rounded-[10px]' src={l.carousel_image} alt={`Carousel ${l.id}`}    onLoad={() => handleImageLoad(l.id)} onClick={()=>navigatePage(l.carousel_link)}
                                            style={{ display: loadingStates[l.id] !== false ? 'none' : 'block' }}/>
                                            
                                                 
                                                 
                                                </>
                                            ))}
                                                <>
                                                    {getAllCarousel.length > 1 && 
                                                        <>
                                                            <button className="scroll-button left absolute top-50 left-0 border-none " id='scroll-left' onClick={() => handleScrollLeftFirst()}> 
                                                                <div className='bg-[#ffffff]  h-[60px] max-sm:h-[30px] max-sm:w-[20px]  flex justify-center items-center w-[30px] rounded-r-[10px]'>
                                                                    <img  className=" w-[40px]  rounded-[20%] max-sm:w-[30px]  " src={LeftArrow} alt=''/>
                                                                    {/* <span className="material-symbols-outlined  text-[40px] rounded-[20%] max-sm:text-[30px]  text-[#545353]">chevron_left</span> */}
                                                                </div>
                                                                </button>
                                                            <button className="scroll-button right  absolute top-50 right-0 border-none " id='scroll-right' onClick={() => handleScrollRightFirst()}>
                                                                <div className='bg-[#ffffff]  h-[60px] max-sm:h-[30px] max-sm:w-[20px] flex justify-center items-center w-[30px] rounded-l-[10px]'>
                                                                    <img  className=" w-[40px]  rounded-[20%] max-sm:w-[30px]  " src={RightArrow}  alt=''/>
                                                                    {/* <span className="material-symbols-outlined  text-[40px] rounded-[20%] max-sm:text-[30px]    text-[#545353]">chevron_Right</span> */}
                                                                </div>
                                                            </button> 
                                                        </>  
                                                    }
                                                </>                                
                                        </div>
                                    </div>
                                     
                            ) : (
                                    <div className='section-1 relative '>
                                        <video className='into-video filter contrast-75 ' autoPlay muted loop   >
                                            <source className='' src='https://d1ikk3qahjralr.cloudfront.net/trippers-media/intro_video.65f430fda65e06b10a34.mp4' type="video/mp4" />
                                        </video>
                                        <div className='serach_and-name z-10  '>
                                            <h1 >Explore with trippers</h1>
                                            <h6>Embark on unforgettable journeys with trippers</h6>                             
                                            {/* <Search /> */}
                                        </div>
                                        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 pointer-events-none"></div>
                                    </div>
                        )}

                      
                


         

                

                <div className='section relative '>
                    <div className=' section-2-container h-[auto]  w-[1184px] mb-[50px] mx-auto  pb-[40px] 
                                     max-xl:w-[980px]
                                     max-lg:w-[700px]
                                     max-md:w-[550px]
                                     max-sm:w-[100%]   max-sm:mt-[-20px]  '>
                        {packageOfferFilter.length > 0 || ActivityOfferFilter.length > 0 ? <Offers_and_Deals /> : "" }              
                       
                        {/* <Discover_Packages/> */}

                        {/* Discover our exclusive packages */}
                        <div className='section-2-row-2    w-[1184px]  relative  
                         max-xl:w-[930px]
                         max-lg:w-[700px]
                         max-md:w-[550px]
                         max-sm:w-[100%] max-sm:px-[16px]  '>
                            <div className='gap-x-2' style={{
                                height: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            
                            }}>
                                <div>
                                    <h1 className=' max-sm:text-[10px] '>Discover Our Exclusive Packages</h1>

                                </div>
                                <Link style={{
                                    textDecoration: 'none',
                                    color: '#B14F37',
                                    fontWeight: 600,
                                   
                                  

                                }} className=' text-[16px]  max-sm:text-[10px] text-nowrap' to='/packagesdata' id='package-details-page'>View All</Link>

                             {/* ------Scroll button----------------------  */}
                                 {screenWidth > 817 ? <>
                                        {/* <button className="scroll-button left absolute left-[-45px] top-[45%]" onClick={scrollLeft}><span className="material-symbols-outlined bg-[#c5c4c4] text-[30px] rounded-[50%]">chevron_left</span></button>
                                        <button className="scroll-button right absolute right-[-45px] top-[45%]" onClick={scrollRight}><span className="material-symbols-outlined bg-[#c5c4c4] text-[30px] rounded-[50%]">chevron_Right</span></button> */}
                                         <button className="scroll-button left absolute left-[-38px] top-[45%]" onClick={() => handleScrollLeft(1)}> <img alt='' src={LeftArrow} className="material-symbols-outlined bg-[#ffffff] w-[30px] rounded-[50%] border border-[#000000] text-[#545353]" id='scrollleft1' /></button>
                                         <button className="scroll-button right absolute right-[-38px] top-[45%]" onClick={() => handleScrollRight(1)}><img alt='' src={RightArrow} className="material-symbols-outlined bg-[#ffffff] w-[30px] rounded-[50%] border border-[#000000] text-[#545353]" id='scrolltight2' /></button>
                                        </>:""}

                            </div>
                         
                        
                           

                             <div className='section-2-row-2-card 
                                        overflow-x-scroll overflow-y-hidden scrollbar-hide  ' style={{whiteSpace:'nowrap',scrollBehavior:'smooth'}} ref={scrollRef1}  >
                                         
                                        
                              
                                {getallPackages?.map((l)=> (
                           
                                <>
                                {l?.is_active === true  &&
                                    <div className='section-2-row-2-card-1 relative  inline-block mr-4 cursor-pointer ' key={l.id} onClick={()=>handlepackage(l.id , l.package_name)}>
                                        
                                         <div className='section-2-row-2-card-1-images'>
                                        
                                         {packageImageLoad[l.id] !== false  && (
                                                    <div className='bg-[#cdcdcd] animate-pulse w-[100%] h-[100%] mb-[10px] rounded-[8px]'> </div>)}
                                                
                                              <>
                                              {/* grayscale-0: No grayscale (default).
                                                  grayscale: Applies full grayscale to the element. */}
                                            <img src={l.package_image[0]} alt=''  onLoad={() => handlepackageimage(l.id)} className={l.public_enabled === true ?'grayscale-0':'grayscale'}  
                                            style={{ display: packageImageLoad[l.id] !== false ? 'none' : 'block' }} /> 
                                            </>
                                            

                                            <div className='section-2-row-2-card-1-details'  >
                                                {/* <h2 className='bg-[#0c0932]'>{l.package_name}</h2> */}
                                                <h2 className=' line-clamp-2 text-wrap mb-2 ' >{l.package_name} </h2>
                                                <div className='section-2-row-2-card-1-days pl-[1px] py-[10px]  '>
                                                    <h6 className='bg-[#000000] px-[12px] rounded-[24px] py-[6px] 
                                                    max-sm:text-[10px]'>{l?.package_duration_day} days &nbsp;{l?.package_duration_night} night</h6>
                                                </div>
                                               
                                                {/* <h4> <strong>$ {l.package_price}</strong> per person</h4> */}
                                                <h4>{l.package_offer==='' || l.package_offer =='0' ?<span>$ {l.package_price}</span>:<>
                                                <span className='font-[400]'><del>$ {l.package_price}</del>&nbsp;&nbsp;<strong>$ {l.package_discount_price}</strong> </span>
                                                        <div className={`w-[50px] h-[30px] ${l.public_enabled === true ? 'bg-[#f97316]' : 'bg-[#3490dc] grayscale'} transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]`}>
                                                        {l.package_offer}% </div>
                                                 </>
                                                } per person</h4>
                                               
                                            </div>
                                        </div> 
                                    </div>
                                    }
                                    </>
                        

                                ))}
                            </div> 
                        </div> 

                        {/* ----------row 3---------------- */}
                    

                        <div className='section-2-row-3 w-[1184px]  relative  
                         max-xl:w-[930px]
                         max-lg:w-[700px] 
                         max-md:w-[550px]
                         max-sm:w-[100%] max-sm:px-[16px] '>
                            <div className='gap-x-2' style={{
                                height: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                
                            }}>
                                <div>
                                    <h1 >Unforgettable Activities Await</h1>
                                </div>
                                <Link style={{
                                    textDecoration: 'none',
                                    color: '#B14F37',
                                    fontWeight: 600,
                                    
                                }} className=' text-[16px]  max-sm:text-[10px] text-nowrap' to='/activitydata' id='activity-details-page'>View All</Link>

                                   {/* ------Scroll button----------------------  */}
                                   {screenWidth > 817 ? <>
                                    <button className="scroll-button left  absolute left-[-38px] top-[45%] " onClick={() => handleScrollLeft(2)}><img  alt='' src={LeftArrow} className="material-symbols-outlined bg-[#ffffff] w-[30px] rounded-[50%] border border-[#000000] text-[#545353]" id='scrollleft1' /></button>
                                    <button className="scroll-button right absolute right-[-38px] top-[45%]" onClick={() => handleScrollRight(2)}><img alt='' src={RightArrow} className="material-symbols-outlined bg-[#ffffff] w-[30px] rounded-[50%] border border-[#000000] text-[#545353]" id='scrolllright2' /></button>

                                        </>:""}
                            </div>


                            <div className='section-2-row-3-card flex
                                             overflow-x-scroll overflow-y-hidden scrollbar-hide   ' style={{whiteSpace:'nowrap',scrollBehavior:'smooth'}}  ref={scrollRef2} >
                            {getActivities?.map((a)=>(  
                                  <>
                                {a?.is_active === true ?                
                                <div className='relative section-2-row-3-card-1  w-[250px]  inline-block mr-4 cursor-pointer '  key={a.id} onClick={()=>handleActivities(a.id ,a.activity_name)}>
                                    <div className='section-2-row-3-card-1-images'>
                                    {ActivityIamgeLoad[a.id] !== false  && (<div className='bg-[#cdcdcd] animate-pulse w-[100%] h-[100%] mb-[10px] rounded-[8px]'> </div>)}                                     
                                        <img src={a?.activity_image[0]} alt='' onLoad={() => handleactivityimage(a.id)} style={{ display: ActivityIamgeLoad[a.id] !== false ? 'none' : 'block' }} className={a.public_enabled === true ?'grayscale-0':'grayscale'}  />
                                        <div className='section-2-row-3-card-1-details'>
                                            <h2 className='line-clamp-1 text-wrap '>{a.activity_name}</h2>
                                            {a.activity_duration === '1'? 
                                             <div className='section-2-row-3-card-1-days'>{a.activity_duration} Hour</div>
                                            : <div className='section-2-row-3-card-1-days'>{a.activity_duration} Hours</div>}
                                           
                                           <h4>
                                            {a?.location ? 
                                            <>
                                                {a?.activity_offer <= 0 ? (
                                                    <span>${a?.location?.reduce((min, loc) => {
                                                        const locOffer = parseFloat(loc.loc_price);
                                                        return locOffer < min ? locOffer : min;
                                                    }, parseFloat(a?.location[0]?.loc_price))}</span>
                                                ) : (
                                                    <>
                                                    <span className='font-[400]'>
                                                        <del>${a?.location[0]?.loc_price}</del>&nbsp;&nbsp;
                                                        <strong>
                                                        ${a?.location?.reduce((min, loc) => {
                                                            const locOffer = parseFloat(loc?.loc_offer);
                                                            return locOffer < min ? locOffer : min;
                                                        }, parseFloat(a?.location[0]?.loc_offer))}
                                                        </strong>
                                                    </span>

                                                    <div
                                                        className={`w-[50px] h-[30px] ${
                                                        a.public_enabled === true ? 'bg-[#f97316]' : 'bg-[#3490dc] grayscale'
                                                        } transform -rotate-45 flex items-center justify-center text-white text-[16px] font-[700] absolute top-[13px] left-[5px]`}
                                                    >
                                                        {a?.activity_offer}%
                                                    </div>
                                                    </>
                                                )} 
                                                </>
                                                : ""}
                                                &nbsp; per person
                                                </h4>
                                        </div>
                                    </div>
                                </div>
                                  :""}
                                    </>
                            ))}
                            </div>
                        </div>
                    </div>

                    {/* ------------------section-3 ----------- */}
                        <Blog/>
                   {/* -------section---4 -------------------*/}
                   <section className='w-[100%]  bg-[#F9F9F9]  h-[523px] mt-[60px] mb-[20px] max-sm:h-[350px]' >
                        <div className='h-[100%] w-[1184px]  mx-auto  
                                      max-xl:w-[980px] 
                                      max-lg:w-[700px] 
                                      max-md:w-[550px] 
                                      max-sm:w-[100%] max-sm:px-[16px] max-sm:mb-4 max-sm:mt-[-40px] '>                  
                            <h1 className='font-[700] text-[28px] text-[#090914] text-center pt-[68px] max-sm:pt-[20px] 
                                           max-sm:text-[24px]'>What our customers say about us?</h1>
                            <div className='mx-auto  h-[256px]  mt-5 max-w-[988px] grid grid-cols-[462px,462px] gap-x-[50px] overflow-x-auto  overflow-y-hidden scrollbar-hide 
                                            max-sm:h-[200px]'>
                                <div className=' px-[24px] py-[20px] bg-[white]  max-sm:h-[200px]  rounded-[8px]'>
                                    <img src={Review} alt='' />
                                    <div className=' h-[88px]  max-sm:h-[50px] '>
                                       <h3 className='font-[500] text-[18px] mt-3
                                                      max-sm:text-[14px]'>“Trippers Maldives made our dream vacation a reality with their exceptional service and personalized itinerary, including unforgettable experiences.."</h3>
                                    </div>
                                    <div className='w-auto h-[56px] flex mt-4  items-center gap-x-3'>
                                        <img  className='max-sm:h-[32px]' src={Profile1} alt='' />
                                        <h6 className='font-[600] text-[18px]
                                                       max-sm:text-[16px]'>Michael Wilson </h6>
                                    </div>

                                </div>
                                <div className=' px-[24px] py-[20px] bg-[#ffffff]  max-sm:h-[200px] rounded-[8px]'>
                                    <img  src={Review} alt='' />
                                    <div className=' h-[88px]  max-sm:h-[50px] '>
                                      <h3 className='font-[500] text-[18px] mt-3
                                                      max-sm:text-[14px]'>“Thanks to Trippers Maldives, we experienced the Maldives in a way we never thought possible."</h3>
                                    </div>
                                    <div className='w-auto h-[56px] flex mt-4  items-center gap-x-3
                                                    max-sm:mt-3'>
                                        <img className='max-sm:h-[32px] max-sm:w-[32px] h-[56px] w-[56px] object-cover rounded-[50%]' src={profile3} alt='' />
                                        <h6 className='font-[600] text-[18px]
                                                       max-sm:text-[16px]'>Matthew Davis</h6>
                                    </div>

                                </div>

                            </div>

                        </div>

                   </section>

                    {/* -------section---5 -------------------*/}
                    <div className='section-4-container  h-[auto] w-[1184px]  mb-[10px] mx-auto mt-[100px]   pb-[80px] 
                                      max-xl:w-[980px] max-xl:mt-[80px] max-xl:pb-[160px]
                                      max-lg:w-[700px] max-lg:h-[auto] max-lg:mt-[70px] max-lg:pb-[140px]
                                      max-md:w-[550px]  max-md:mt-[50px] max-md:pb-[100px]
                                      max-sm:w-[100%] max-sm:px-[16px] max-sm:mt-[0px] max-sm:pb-[20px] '>
                        {/* <video className='beach-video  filter contrast-75  ' autoPlay muted loop >
                            <source src='https://d1ikk3qahjralr.cloudfront.net/trippers-media/beach_video.07469cd88317e1cadfaa.mp4' type="video/mp4" />
                        </video> */}
                        <div className='beach-video'>
                            <iframe  className='w-[100%] h-[400px] 
                                                max-md:h-[300px]
                                                max-sm:h-[200px] ' src="https://www.youtube.com/embed/XhCkptbe7Z4">
                            </iframe>
                        </div>

                        {/* <h2 className='text-[32px]
                                       max-lg:text-[30px]
                                       max-md:text-[28px]
                                       max-sm:text-[20px] max-sm:mt-[-15px]  max-sm:mr-[8px] '>About trippers</h2> */}
                    </div>

             <Whatsapp/>
                </div>


            </div>
            <Footer />

{/* google authentication */}


</> } 
{/* loader end */}
        </div>

    )
}

export default Home
