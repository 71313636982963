import React, { useEffect, useState } from 'react'
import Beach from '../Images/beach.png';
import Offers_Nav from '../../components/Offers_Nav.js'
import Hamburger from "../../components/Hamburger_Nav.js"
import Footer from '../../components/Footer.js';
import { Link } from 'react-router-dom';
import {BlogSingleView} from '../../Utilis/BaseUrl.js'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import skeletonImage from '../Images/sheleton loader image.png'
import Logo from '../Images/loaderLogo.png'






function BlobArticleOne() { 

  const [blog, setblog] = useState('');
  const navigate = useNavigate();
  
  let blog_id;
  const { id } = useParams(); 

  if(id){
    blog_id = id;
    console.log("blog_id1")
  }else{
    blog_id = localStorage.getItem('blog_id');
    console.log("blog_id1")

  }

  //loader
  const [loader ,setloader] = useState(true)
  const [blogImage1, setblogImage1] = useState(true);
  const [isFontLoaded, setIsFontLoaded] = useState(true);

  function session() {
    blog_id =localStorage.getItem('blog_id');
  }

  const listSingleBlogs = async ()=>{
    try{
      const response = await axios.post(`${BlogSingleView}`,{"_id":blog_id});

      if(response?.data?.status === true){
        setblog(response?.data?.data);     
        setloader(false)    
        setIsFontLoaded(false)                      
      } else{
        navigate (`/`)
      }
    }catch(error){
        // console.log(error)
        navigate('/404')
    }
  }



  useEffect(()=>{
    session();
  },[blog_id])

  useEffect(()=>{
    listSingleBlogs();
  },[])


  function formatDate(dateString) {

    const date = new Date(dateString);

    // Extract the month name (e.g., "Jun")
    const month = date.toLocaleString('en-US', { month: 'short' });

    // Extract the day of the month (e.g., "07")
    const day = String(date.getDate()).padStart(2, '0');

    // Extract the last two digits of the year (e.g., "24")
    const year = String(date.getFullYear()).slice(-2);

    // Combine them into the desired format
    return ` ${day} ${month} ${year} `;
  }

  const formattedDate = formatDate(blog?.createdAt);
  // console.log(formattedDate); // Output: "Sep 17 24"

  const handlepackageimage1 = () => {
    setblogImage1(false)
  };

  return (

  <div>  
    <>
    {/* loader------ */}
      {loader ? 
        <div className='flex items-center justify-center flex-col h-[100vh]  '>
          <img className='h-[60px]' src={Logo} alt='' />
          <div className="dots mt-3"></div>
        </div>
      : 
        <>
          <Offers_Nav />
          <Hamburger/>
          <div className="  mt-10 mx-auto h-auto max-w-[1184px] mb-[100px] 
                            max-xl:w-[980px] 
                            max-lg:w-[700px] 
                            max-md:w-[550px] 
                            max-sm:w-[100%] max-sm:px-[16px] max-sm:mt-4 "> 
            <Link to='/' id='back-navigation'><span className="material-symbols-outlined ">{isFontLoaded? '' :'arrow_back'} </span> </Link>
            <div className="flex  mt-2  gap-[2%] max-w-full  mb-[80px] 
                            max-md:flex-col  ">
              <div className="w-full h-full 
                              max-lg:w-full
                              max-md:w-full  ">

                <div className='w-full  h-[380px] mb-4
                                max-sm:h-[200px]'>
                  {blogImage1 && <img src={skeletonImage} alt='' className='animate-pulse rounded-[16px] object-cover h-[100%] w-[100%]'/> }
                  <img className='rounded-[16px] object-cover h-[100%] w-[100%] ' src={blog.blog_image} alt='' onLoad={() => handlepackageimage1()}  style={{ display: blogImage1 ? 'none' : 'block' }}/>
                </div>

                <section className=' mt-4 max-md:mb-4 '>
                  <div className='font-montserrat text-[24px] max-sm:text-[20px]  font-bold leading-27 text-left '>{blog.blog_heading}</div> 
                  <div className='mt-2 text-[#5F5F5F] font-montserrat text-base font-[500] leading-11 text-left mb-3 '>{formattedDate}</div>

                  <h1 className='font-[400] text-[16px] text-[#040404] 
                                  max-sm:text-[13px] leading-6 '  >
                                      {blog.blog_description.split('\n').map((line, index) => (
                                        <div className='mb-3'>
                                          <p key={index}>{line}</p>
                                        </div>
                                      ))}
                  </h1>    
                </section>

              </div>
            </div>
          </div>
          <Footer/>    
        </>
      }
    </>
  </div>
  )
}

export default BlobArticleOne