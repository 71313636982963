import React from 'react'
import Activities from './Activities.js';

import CardOne from "./Images/cardone.png";
import CardTwo from "./Images/cardtwo.png";
import CardThree from "./Images/cardthree.png";
import CardFour from "./Images/cardfour.png";
import CardFive from "./Images/cardfive.png";
import Card from "./Images/card.png";



function ActivityData() {
    const activities = [
        {
            id: 1,
            title: 'Hulhumale: Full Day Boat Tour and Snorkeling',
            image: CardOne,
            hours: '6 Hours',
            rate: '$ 145',
        },
        {
            id: 2,
            title: 'Hulhumale: Full Day Kayaking',
            image: CardTwo,
            hours: '6 Hours',
            rate: '$ 145',
        },
        {
            id: 3,
            title: 'Hulhumale: Full Day Parasailing',
            image: CardThree,
            hours: '8 Hours',
            rate: '$ 145',
        },
        {
            id: 4,
            title: 'Atoll: Speed Boating Kayaking, snorkeling',
            image: CardFour,
            hours: '7 Hours',
            rate: '$ 145',
        },
        {
            id: 5,
            title: 'Malé: Maldives Full Fun Adventure Trip',
            image: CardFive,
            hours: '7 Hours',
            rate: '$ 145',
        },
        {
            id: 6,
            title: 'Private Photography in Hulhumale’, Maldives',
            image: Card,
            hours: '6 Hours',
            rate: '$ 145',
        },


      
    ];

    return (
        <>
            <Activities
            data={activities}
            />
        </>
    )
}


export default ActivityData