import React from 'react'
import Packages from './Packages';
import Island from './Images/island.png';
import Tree from './Images/tree.png';
import Underwater from './Images/underwater.png';
import Swimmingpool from './Images/swimmingpool.png';
import Private from './Images/packagecard.png'


function PackagesData() {




    const packagebudgetdata = [
        { id: 1, label: '50$ - 150$' },
        { id: 2, label: '150$ - 250$' },
        { id: 3, label: '150$ - 250$' },
        { id: 4, label: '150$ - 250$' },
        { id: 5, label: '150$ - 250$' }
      ];

      const packagedaydata = [
        { id: 1, label: 'Upto 3 Days' },
        { id: 2, label: '3-6 Days'},
        { id: 3, label: '9 Days'},
        { id: 4, label: '9 Days'},
        { id: 5, label: '12 Days'},
        { id: 6, label: '15 Days'},
      ]

      const carddata=[
        {
          id: 1,
          title: 'Magical Maldives Vacation',
          image:  Island,
          hours: '3 Nights',
          rate: '$ 145',
      },
      {
        id:2,
        title: 'Paradise Getaway Package',
        image:  Tree,
        hours: '7 Nights / 8 Days',
        rate: '$ 145',
    },
    {
      id: 3,
      title: 'Underwater Adventure Package',
      image:  Underwater,
      hours: '4 Nights',
      rate: '$ 145',
    },
    {
      id: 4,
      title: 'Romantic Retreat Package',
      image:  Swimmingpool,
      hours: '3 Nights',
      rate: '$ 145',
    },
    {
      id: 5,
      title: 'Private Photography in Hulhumale’, Maldives',
      image:  Private,
      hours: '3 Nights',
      rate: '$ 145',
    },
    






      ]





  return (
    
            <Packages
            dataone={packagebudgetdata}
            datatwo={packagedaydata}
            datacard={carddata}

            />
        
  )
}

export default PackagesData
