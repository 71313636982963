import React from 'react';
import Plane from '../Pages/Images/plane.png';
import Lady from '../Pages/Images/lady.png';
import { useNavigate } from 'react-router-dom';
import { useState,useEffect ,useRef } from 'react';
import axios from 'axios';
import {viewAllPackages , viewAllActivity} from '../Utilis/BaseUrl.js'
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import skeletonImage from '../Pages/Images/sheleton loader image.png'
import RightArrow from '../Pages/Images/right arrow.svg'
import LeftArrow from '../Pages/Images/left arrow.svg'


function Offers_and_Deals() {
    const navigate = useNavigate();

    const scrollRef = useRef(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    // loader
    const [loaderpackage ,setloaderpackage ] = useState(true)
    const [loaderactivity ,setloaderactivity ] = useState(true)


          
    //   list all packages 
    const [getallPackages,setallAPackages] = useState([])
    const ListAllPackages = async ()=>{
      try{

          const response = await axios.get(`${viewAllPackages}`);
        //   console.log("response===============",response)

          if(response){
              setallAPackages(response.data.data); 
              setloaderpackage(false)
                                     
          }
      }catch(error){
        //   console.log(error)
      }
    }

    //   all activities 
    const [getActivities ,setActivities] = useState([])
    const ListAllActivities = async ()=>{
      try{
          const response= await axios.get(`${viewAllActivity}`);
          if(response){
              setActivities(response.data.data);
              setloaderactivity(false)
          }
      }catch(error){
        //   console.log(error)
      }
    }


    useEffect(()=>{
      ListAllPackages();
      ListAllActivities();

      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };

    },[]);

    const handlepackage = (id ,package_name)=>{
        sessionStorage.setItem('Package_id',id);
        const slug = slugify(package_name)
        navigate (`/packagebooking/${slug}/${id}`)
      };

      const handleActivities = (id,activity_name)=>{
        sessionStorage.setItem('Activity_id',id);
        const slug = slugify(activity_name)
        navigate(`/activitybooking/${slug}/${id}`)
        
      }
    //  console.log("getallPackages============",getActivities)

//scroll
const handleScrollLeft = () => {
  if (scrollRef.current) {
    scrollRef.current.scrollLeft -= 100;
  }
};

const handleScrollRight = () => {
  if (scrollRef.current) {
    scrollRef.current.scrollLeft += 100;
  }
};



    //package image loader
    const [packageImageLoad, setpackageImageLoad] = useState({});
           
    const handlepackageimage = (id) => {
    setpackageImageLoad((prevStates) => ({
       ...prevStates,
      [id]: false,
       
      }));
    };

            //activity image loader
            const [ActivityIamgeLoad, setActivityIamgeLoad] = useState({});
           
            const handleactivityimage = (id) => {
              setActivityIamgeLoad((prevStates) => ({
                ...prevStates,
                [id]: false,
              }));
            };

    //filtering offer
    const packageOfferFilter = getallPackages.filter((offerFilter)=>
      offerFilter.package_offer >'0' && offerFilter.package_offer !='' && offerFilter.public_enabled ==true  && offerFilter.is_active == true 
    );
  //   console.log("offeer",packageOfferFilter)

  const ActivityOfferFilter = getActivities.filter((getActivities) =>
      getActivities.activity_offer > '0' && getActivities.activity_offer !='' && getActivities.public_enabled ==true  && getActivities.is_active == true 
      ); 

      // console.log("ActivityOfferFilter",ActivityOfferFilter)

  return (
    <div>
      
        <div className=' mx-auto h-auto w-[1184px] max-sm:px-[16px]   scrollbar-hide 
                           max-xl:w-[980px]
                           max-lg:w-[700px]
                           max-md:h-[30px]  max-md:w-[550px]
                           max-sm:w-[100%] max-sm:overflow-x-scroll max-sm:overflow-y-hidden   relative 
                         '>
                          
                          <div className='flex justify-between'>
                                  <h1 className='mb-2 font-[700] text-[28px] leading-[50px] 
                                                max-lg:text-[25px]
                                                max-md:text-[20px]
                                                max-sm:text-[18px] '>Offers and Deals</h1>
                                      
                            {screenWidth > 817 ? <>
                              <Link style={{
                                textDecoration: 'none',
                                color: '#B14F37',
                                fontWeight: 600,}} className=' text-[16px] mr-[100px] mt-[15px] max-sm:text-[10px] text-nowrap' to='/offers' id='view-all-offer-and-deals'>View All</Link>
                           <button className="scroll-button left absolute top-7 right-10 " onClick={() => handleScrollLeft()}> <img  src={LeftArrow} className="material-symbols-outlined bg-[#ffffff] w-[30px] rounded-[50%] border border-[#000000] text-[#545353]" id='scrollleft' /></button>
                           <button className="scroll-button right  absolute top-7 right-0" onClick={() => handleScrollRight()}><img src={RightArrow}  className="material-symbols-outlined bg-[#ffffff] w-[30px] rounded-[50%]  border border-[#000000] text-[#545353]"  id='scrollright' /> </button>
                           </>:  <Link style={{
                             textDecoration: 'none',
                             color: '#B14F37',
                             fontWeight: 600,}} className=' text-[16px]  max-sm:text-[10px] mt-[15px] text-nowrap' to='/offers' id='view-all-offer-and-deals' >View All</Link>}
                             </div>
              
            <div className='Offers-and-deals-container scrollbar-hide ' style={{whiteSpace:'nowrap',scrollBehavior:'smooth'}}   ref={scrollRef}>
              <>
              { loaderpackage === true && loaderactivity === true ? 
                <div className="flex items-center justify-center">
                <div className="w-12 h-12 max-sm:w-6  max-sm:h-6 border-4 border-orange-500 border-t-transparent border-t-4 rounded-full animate-spin"></div>
              </div>
              :
            <> 
           {/* ------offer package--------- */}
           {getallPackages?.map((l)=> (
            <>
            {l?.package_offer > '0'  && l?.is_active === true && packageOfferFilter.length > 0 && l.public_enabled === true &&
            <>
                
                <div className='Offers-and-deals-container-card-1 mr-4  '  >
                    <div className='Offers-and-deals-container-card-1-details ' style={{fontFamily:'"Montserrat", sans-serif'}}>
                        <h6> Packages</h6>
                        <div className='Offers-and-deals-container-card-1-details-2 '>
                            <h2 className='line-clamp-2 text-wrap'>{l?.package_name} </h2>
                            <h1 className='mt-[15px]'>{l?.package_offer}% Off</h1>
                            <button className='mt-[20px]' key={l?.id} onClick={()=>handlepackage(l.id,l?.package_name)} id='book-now' >Book Now</button>
                        </div>
                    </div>
                    <div className='Offers-and-deals-container-card-1-imges'>
                    {packageImageLoad[l.id] !== false  && (
                                                    <img  src= {skeletonImage} className='animate-pulse'  alt='' />)}
                        <img src= {l.package_image[0]} alt='' onLoad={() => handlepackageimage(l.id)}
                                            style={{ display: packageImageLoad[l.id] !== false ? 'none' : 'block' }} />

                    </div>
                </div>
                </>
                }
                </>
               ))}

                  {/* ------offer Activities--------- */}
                  {getActivities.map((a)=>(    
                    <>
                    {a.activity_offer > '0'  && a?.is_active === true && ActivityOfferFilter.length > 0  &&  a?.public_enabled === true &&
                <div className='Offers-and-deals-container-card-2 mr-4'>
                <div className='Offers-and-deals-container-card-2-details'>
                        <h6>Activities</h6>
                        <div className='Offers-and-deals-container-card-2-details-2'>
                            <h2 className='line-clamp-2 text-wrap'>{a.activity_name} </h2>
                            <h1 className='mt-[15px]' >{a.activity_offer}% Off</h1>
                            <button className='mt-[20px]'key={a.id} onClick={()=>handleActivities(a.id,a.activity_name)} id='book-now' >Book Now</button>
                        </div>
                    </div>
                    <div className='Offers-and-deals-container-card-2-imges'>
                    {ActivityIamgeLoad[a.id] !== false  && (<img  src= {skeletonImage} className='animate-pulse'  alt='' />)}  
                        <img src={a.activity_image[0]} alt='' onLoad={() => handleactivityimage(a.id)}
                                            style={{ display: ActivityIamgeLoad[a.id] !== false ? 'none' : 'block' }} />

                    </div>
                </div> }
                </>
                  ))}
                  </>}
            </>
            </div>

</div>
    </div>
  )
}

export default Offers_and_Deals
