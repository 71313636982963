import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import {BlogAllView} from '../Utilis/BaseUrl.js'
import { useEffect,useState } from 'react';
import axios from 'axios';
import skeletonImage from '../Pages/Images/sheleton loader image.png'



function Blog() {

      //   list all blogs
      const [getblogs,setblogs] = useState([])
      const [loader ,setloader ] = useState(true)
      const navigate = useNavigate();


      const listAllBlogs = async ()=>{
        try{
            const response = await axios.get(`${BlogAllView}`);
            if(response?.data?.status === true){
              // console.log("response",response?.data?.data)
              setblogs(response.data.data); 
              setloader(false)                             
            } 
        }catch(error){
            // console.log(error)
            setloader(false)
        }
      }

      useEffect(() => {

          //functions
          listAllBlogs();

        }, []);

        const handleblog = (id)=>{
          localStorage.setItem('blog_id',id);
           navigate (`/BlogArticle/${id}`)
        };

    // image loader
    const [blogImage1, setblogImage1] = useState(true);
    const [blogImage2, setblogImage2] = useState(true);
    const [blogImage3, setblogImage3] = useState(true);
           
    const handlepackageimage1 = () => {
      setblogImage1(false)
    };
    const handlepackageimage2 = () => {
      setblogImage2(false)
    };
    const handlepackageimage3 = () => {
      setblogImage3(false)
    };

  return (
    <>
    { getblogs?.length > 0 && 
     <>
        { loader === true  ? 
          <div className="flex items-center justify-center">
          <div className="w-12 h-12 max-sm:w-6  max-sm:h-6 border-4 border-orange-500 border-t-transparent border-t-4 rounded-full animate-spin"></div>
          </div>
        :
          <div className='h-[auto] w-[1184px] mb-[30px] mx-auto  
                          max-xl:w-[980px] 
                          max-lg:w-[700px] max-lg:h-[auto]
                          max-md:w-[550px] 
                          max-sm:w-[100%] max-sm:px-[16px]' style={{fontFamily:'Montserrat", sans-serif'}}>

              <div className='w-[100%] h-[auto] mb-[18px] '>
                  <h1 className='text-[28px] font-[700] leading-[50px] text-[#101828]
                                  max-lg:leading-[40px] 
                                  max-md:text-[20px] max-md:leading-[30px] max-md:mb-[20px]  
                                  max-sm:text-[17px] max-sm:leading-[25px] max-sm:mb-[22px]' >Stories and Insights from Our Adventures Around the World</h1>
              </div>

          {/*------------------------------ more than 3 blog---------------------------------------- */}
              { getblogs?.length >= 3 && 
                  <div className='section-3-row-1 '> 
                      <>
                      
                          <div className='section-3-row-1-col-1 ' key={getblogs[0]?._id} onClick={()=>handleblog(getblogs[0]?._id)}>
                            <div className='section-3-row-1-col-1-image'>
                              {blogImage1 && <img src={skeletonImage} alt='' className='animate-pulse'/> }
                              <img src={getblogs[0]?.blog_image} alt='' onLoad={() => handlepackageimage1()}  style={{ display: blogImage1 ? 'none' : 'block' }}/>
                              
                           
                            </div> 
                            <div className='section-3-row-1-col-1-details '>
                              <h1 className='line-clamp-2'>{getblogs[0]?.blog_heading}</h1>
                              <h5 className='line-clamp-2'>{getblogs[0]?.blog_description}</h5>
                            </div>
                          </div> 
                      

                        <div className='section-3-row-1-col-2 '>
                        
                            <div className='section-3-row-1-col-2-row-1   ' key={getblogs[1]?._id} onClick={()=>handleblog(getblogs[1]?._id)}>
                              <div className='section-3-row-1-col-2-row-1-image'>
                              {blogImage2 && <img src={skeletonImage} alt='' className='animate-pulse'/> }
                              <img src={getblogs[1]?.blog_image} alt='' onLoad={() => handlepackageimage2()}  style={{ display: blogImage2 ? 'none' : 'block' }}/>
                              </div>
                              <div className='section-3-row-1-col-2-row-1-details '>
                                <h2 >{getblogs[1]?.blog_heading}</h2>
                                <h3 >{getblogs[1]?.blog_description}</h3>
                              </div>
                            </div>
                        

                      
                            <div className='section-3-row-1-col-2-row-2 ' key={getblogs[2]?._id} onClick={()=>handleblog(getblogs[2]?._id)}>
                              <div className='section-3-row-1-col-2-row-2-image'>
                              {blogImage3 && <img src={skeletonImage} alt='' className='animate-pulse'/> }
                              <img src={getblogs[2]?.blog_image} alt='' onLoad={() => handlepackageimage3()}  style={{ display: blogImage3 ? 'none' : 'block' }}/>
                              </div>
                              <div className='section-3-row-1-col-2-row-2-details'>
                                <h2 >{getblogs[2]?.blog_heading}</h2>
                                <h3 >{getblogs[2]?.blog_description}</h3>
                              </div>
                            </div>
                        

                        </div>
                      </>
                  </div>
              }
          {/*------------------------------- 2 blog------------------------------------------------- */}
              { getblogs?.length === 2 && 
                <>
                  <div className='w-full h-auto grid grid-cols-1 gap-x-9 
                                  lg:grid-cols-2 
                                  max-sm:mb-[90px]'>

                    <div key={getblogs[0]?._id} onClick={()=>handleblog(getblogs[0]?._id)} >
                      <div className='w-[100%] h-[332px] rounded-3 
                                      max-sm:h-[232px]'>
                        {blogImage1 && <img src={skeletonImage} alt='' className='animate-pulse w-[100%] h-[100%] object-cover rounded-3'/> }
                        <img className='w-[100%] h-[100%] object-cover rounded-3' src={getblogs[0]?.blog_image} alt='' onLoad={() => handlepackageimage1()}  style={{ display: blogImage1 ? 'none' : 'block' }}/>
                      </div>
                      <div className='w-[95%]  h-[124px] mt-[32px] 
                                      max-lg:w-[100%] max-lg:h-[auto] max-lg:mb-5
                                      max-sm:mt-[24px]'>
                        <h2 className='font-[600] text-[24px] leading-[27px] text-[#343434] line-clamp-1
                                        max-xl:text-[22px]
                                        max-md:text-[20px]
                                        max-sm:text-[16px]
                                        ' >{getblogs[0]?.blog_heading}</h2>
                        <h3 className='
                                        font-[400] text-[16px] leading-[24px] text-[#555555] mt-[9px] line-clamp-3
                                        max-xl:text-[14px] max-xl:leading-[20px] 
                                        max-lg:line-clamp-2
                                        max-md:text-[13px]
                                        '>{getblogs[0]?.blog_description}</h3>
                      </div>
                    </div>

                    <div key={getblogs[1]?._id}  onClick={()=>handleblog(getblogs[1]?._id)}>
                      <div className='w-[100%] h-[332px] rounded-3 
                                      max-sm:h-[232px]'>
                          {blogImage2 && <img src={skeletonImage} alt='' className='animate-pulse w-[100%] h-[100%] object-cover rounded-3'/> }
                          <img className='w-[100%] h-[100%] object-cover rounded-3' src={getblogs[1]?.blog_image} alt='' onLoad={() => handlepackageimage2()}  style={{ display: blogImage2 ? 'none' : 'block' }}/>

                      </div>
                      <div className='w-[95%]  h-[124px] mt-[32px] 
                                      max-lg:w-[100%] max-lg:h-[auto] max-lg:mb-5
                                      max-sm:mt-[24px]'>
                        <h2 className='font-[600] text-[24px] leading-[27px] text-[#343434] line-clamp-1
                                        max-xl:text-[22px]
                                        max-md:text-[20px]
                                        max-sm:text-[16px]
                                        ' >{getblogs[1]?.blog_heading}</h2>
                        <h3 className='
                                        font-[400] text-[16px] leading-[24px] text-[#555555] mt-[9px] line-clamp-3
                                        max-xl:text-[14px] max-xl:leading-[20px] 
                                        max-lg:line-clamp-2
                                        max-md:text-[13px]
                                        '>{getblogs[1]?.blog_description}</h3>
                      </div>
                    </div>

                  </div>
                </>
              }
          {/*-------------------------------- 1 blog------------------------------------------------ */}
              { getblogs?.length === 1 && 
                <>
                  <div className='w-full h-auto grid grid-cols-1 gap-x-9 mt-[12px]
                                  max-sm:mb-[90px]'>

                    <div key={getblogs[0]?._id}  onClick={()=>handleblog(getblogs[0]?._id)}>
                      <div className='w-[100%] h-[332px] rounded-3 
                                      max-sm:h-[232px]'>
                        {blogImage1 && <img src={skeletonImage} alt='' className='animate-pulse w-[100%] h-[100%] object-cover rounded-3'/> }
                          <img className='w-[100%] h-[100%] object-cover rounded-3' src={getblogs[0]?.blog_image} alt='' onLoad={() => handlepackageimage1()}  style={{ display: blogImage1 ? 'none' : 'block' }}/>
                      </div>
                      <div className='w-[95%]  h-[124px] mt-[32px] 
                                      max-lg:w-[100%] max-lg:h-[auto] max-lg:mb-5
                                      max-sm:mt-[24px]'>
                        <h2 className='font-[600] text-[24px] leading-[27px] text-[#343434] line-clamp-1
                                        max-xl:text-[22px]
                                        max-md:text-[20px]
                                        max-sm:text-[16px]
                                        ' >{getblogs[0]?.blog_heading}</h2>
                        <h3 className='
                                        font-[400] text-[16px] leading-[24px] text-[#555555] mt-[9px] line-clamp-3
                                        max-xl:text-[14px] max-xl:leading-[20px] 
                                        max-lg:line-clamp-2
                                        max-md:text-[13px]
                                        '>{getblogs[0]?.blog_description}</h3>
                      </div>
                    </div>
                  </div>
                </>
              }
          </div>
        }
      </>
    }
    </>
  )
}

export default Blog