import React from 'react';
import Whatapp from './Images/2062095_application_chat_communication_logo_whatsapp_icon.svg.webp'

function Whatsapp() {

    //footer illum number unde athum mattanam;
    var phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
    const url = `https://wa.me/${phoneNumber}`;
    
  return (
    <div className='fixed bottom-8 right-10 max-sm:right-6 max-sm:bottom-8 z-10'>
    <a href={url} target="_blank" rel="noopener noreferrer">
     <img className='h-[46px]' src={Whatapp} alt=''/>
    </a>
    </div>
  )
}

export default Whatsapp