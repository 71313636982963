import React, { useState } from 'react';
import Logo from './Images/logos.png';
import Maldives_logo from './Images/maldives_logo.svg'
import { NavLink } from 'react-router-dom';
import Flag from './Images/flag.png';
import img1 from '../Pages/Images/Frame132.png'
import img2 from '../Pages/Images/image123.png'
import goolgeLogo from './Images/Google_Icons.webp';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useEffect } from 'react';
import {Modal} from 'antd'
import Warning from './Images/warning.webp'

function Hamburger_Nav(props) {


  const [isOpen, setIsOpen] = useState(false);
  const [userData,setUserDate] =  useState([])
  const [visible ,setVisible] = useState(false)
  const [open,setopen] = useState(false)
  const [logoutAlert,setlogoutAlert] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const data =   localStorage.getItem('myDate');

 

  const login = useGoogleLogin({
    onSuccess: async(response) =>{
      // console.log("first,",response)
    
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers:{
              Authorization:`Bearer ${response.access_token}`,   
            },
            
          }
        );
        // console.log("token",response.access_token)
     
        // setUserDate(res?.data)
        // sessionStorage.setItem('myDate',JSON.stringify(res?.data));
        localStorage.setItem('myDate',JSON.stringify(res?.data));
        setUserDate(res?.data)
       props.callback();
       

      } catch (error) {
        // console.log(error)
        
      }
      setVisible(false)
    }
  
  });

  function session(){

  
  const sessionUserData =localStorage.getItem('myDate')
  if(sessionUserData)
    {
      // console.log("-------------------",JSON.parse(sessionUserData))
      // console.log(userData)
      setUserDate(JSON.parse(sessionUserData))
    }
  }
  useEffect(()=>{
    session();
  },[data])



  useEffect(()=>{

   
      window.scrollTo({
        top:0
      })
   
      // console.log("first,",(userData.name))

      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
     

  },[userData]);


  // useEffect(() => {
  //    console.log(userData)
  // }, [userData])
  
//remove session storage 
const HandleLogout = () => {
  // Remove session storage item
  // sessionStorage.removeItem('myDate');
  localStorage.removeItem('myDate');
  setUserDate([])
  setlogoutAlert(false)
};



  return (
    <nav className="bg-white border border-b-gray-100 ">
      <div className=" h-auto w-[1184px]  mx-auto
                        max-xl:w-[950px]
                        max-lg:w-[700px]
                        max-md:w-[500px]
                        max-sm:px-[16px]   max-sm:w-[100%]   
      ">
        <div className="flex justify-between items-center h-16  ">
        <NavLink to="/" id='homepage-link'>
          <div className="flex-shrink-0 flex items-center    ">
            <img className="h-[45px] max-sm:h-[37px]" src={Maldives_logo} alt="Logo" />
            {/* <span className="text-black text-xl max-sm:text-[16px] font-bold ml-4"></span> */}
          </div>
          </NavLink>
          <div className="md:hidden flex">
            <button onClick={() => setIsOpen(!isOpen)} className="text-black" id='hamberger-button'>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
       
          {userData?
          <div className="hidden md:flex justify-center items-center ml-[-150px] ">
            <div className="flex items-center">
              <NavLink to="/packagesdata" id='package-details-page'>
                {({ isActive }) => (
                  <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black   px-3 py-2  text-base font-medium"}>Packages</span>
                )}
              </NavLink>
              <NavLink to="/activitydata" id='activity-details-page'>
                {({ isActive }) => (
                  <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black   px-3 py-2  text-base font-medium"}>Activities</span>
                )}
              </NavLink>
              <NavLink to="/offers" id='offer-details-page'>
                {({ isActive }) => (
                  <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black   px-3 py-2  text-base font-medium"}>Offers</span>
                )}
              </NavLink>
            </div>
          </div>
          :
          <div className="hidden md:flex justify-center items-center ">
            <div className="flex items-center">
              <NavLink to="/packagesdata" id='package-details-page'>
                {({ isActive }) => (
                  <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black   px-3 py-2  text-base font-medium"}>Packages</span>
                )}
              </NavLink>
              <NavLink to="/activitydata" id='activity-details-page'>
                {({ isActive }) => (
                  <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black   px-3 py-2  text-base font-medium"}>Activities</span>
                )}
              </NavLink>
              <NavLink to="/offers" id='offer-details-page'>
                {({ isActive }) => (
                  <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black   px-3 py-2  text-base font-medium"}>Offers</span>
                )}
              </NavLink>
            </div>
          </div>
          }

          <div className=' hidden md:flex items-center '>

             {/* {userData.length== 0 ?
             <div className='login-signup-btn  w-[215px]'>
               <img className="h-[29px] mr-1"src={goolgeLogo} alt='' />
             <NavLink to="" className='login-signup-btn-link'>
               <div className='text-base font-medium '>
               <button className='text-[15px] font-medium bg-[#DE6A29] text-[white]' onClick={() => login()}>
              <span>Sign-in</span>  </button>
               </div> 
             </NavLink>
           </div>:
           <div className='login-signup-btn  w-[143px] '>
           <NavLink to="" className='login-signup-btn-link'>
             <div className='text-base font-medium '>
             <button className='text-[15px] font-medium'  onClick={HandleLogout} >
            <span>Logout</span>  </button>
             </div> 
           </NavLink>
         </div>} */}
           {userData.length == 0 ? 
            <button className=' font-medium bg-[#DE6A29] text-[white] py-2 px-4 text-[14px] rounded-[8px]' id='sigin-button' onClick={() => setVisible(true)}>
              <span>Sign-in</span>  </button>:
            
                <div
                className=" max-sm:right-[20px] bg-[#dedddd]  w-[48px] h-[48px] rounded-[50%] flex items-center justify-center
                                   max-sm:h-[40px]  max-sm:w-[40px] cursor-pointer "
                onClick={() => {
                  setopen(true);
                }}
              >
                <h3 className="font-bold  text-[16px] text-[#787777]">{userData?.email.charAt(0).toUpperCase()}</h3>
              </div>
             
              }



            
          </div>
        </div>
      </div>
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <div className="mx-auto pt-2 pb-3 space-y-1 sm:mx-auto">
          <NavLink to="/packagesdata" id='package-details-page'>
            {({ isActive }) => (
              <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black block  px-3 py-2  text-base font-medium"}>Packages</span>
            )}
          </NavLink>
          <NavLink to="/activitydata" id='activity-details-page'>
            {({ isActive }) => (
              <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black block  px-3 py-2  text-base font-medium"}>Activities</span>
            )}
          </NavLink>
          <NavLink to="/offers" id='offer-details-page'>
            {({ isActive }) => (
              <span className={isActive ? "text-[#FD8062]  px-3 py-2 text-base font-semibold" : "text-black block  px-3 py-2  text-base font-medium"}>Offers</span>
            )}
          </NavLink>
        </div>
        <div className="pb-3 mx-auto">
        <div className='block items-center'>
          {/* <img className="h-[32px]"src={Flag} alt='' /> */}
          {/* {userData.length== 0 ?
             <div className='login-signup-btn  w-[193px]'>
               <img className="h-[29px] mr-1"src={goolgeLogo} alt='' />
             <NavLink to="" className='login-signup-btn-link'>
               <div className='text-base font-medium '>
               <button className='text-[13px] font-medium' onClick={() => login()}>
              <span>Sign in with Google</span>  </button>
               </div> 
             </NavLink>
           </div>:
           <div className='login-signup-btn  w-[143px] '>
           <NavLink to="" className='login-signup-btn-link'>
             <div className='text-base font-medium '>
             <button className='text-[15px] font-medium'  onClick={HandleLogout} >
            <span>Logout</span>  </button>
             </div> 
           </NavLink>
         </div>} */}
          {userData.length == 0 ? 
            <button className=' font-medium bg-[#DE6A29] text-[white] py-2 px-4 ml-3 text-[14px] rounded-[8px]' id='signin-button' onClick={() => setVisible(true)}>
              <span>Sign-in</span>  </button>:
                 <div
                 className=" max-sm:right-[20px] bg-[#dedddd] w-[48px] h-[48px] rounded-[50%] flex items-center justify-center
                                    max-sm:h-[40px]  max-sm:w-[40px] cursor-pointer ml-4"
                 onClick={() => {
                   setopen(true);
                 }}
               >
                 <h3 className="font-bold  text-[16px] text-[#545454]">{userData?.email.charAt(0).toUpperCase()}</h3>
               </div>}

        </div>
        </div>
        {/* {screenWidth >= 1184 ?  */}
        
        <Modal onCancel={()=>setVisible(false)}
          footer={null}
          visible={visible}
          width={969}
          //  wrapClassName="full-size-modal"
        >
            <div>
              <div className='flex  max-md:justify-center max-md:h-[300px]  '  style={{fontFamily:'"Montserrat", sans-serif'}}>
              {screenWidth >= 768 ?  
              <div className='relative' >
              <img src={img2} alt="Full-size content"  />
              <img className='absolute top-[30%] left-[25%] ' src={img1} alt="Full-size content"  />
              </div>
              :""}
             <div className='flex justify-center pt-[-90px] items-start mx-4 flex-col 
                               '>
              <img className='h-[41px]  max-sm:h-[32px]  max-sm:mb-5' src={Maldives_logo} alt=''/>
              <h1 className='text-[24px] font-[700] mt-2 
                             max-sm:text-[20px]  max-sm:mb-5'>Welcome to Trippers Maldives </h1>
              <button className='w-[100%] bg-[#EEEEEE] flex justify-center items-center py-[10px] mt-3 rounded-[8px]' id='login-button' onClick={() => login()}>
              <img className="h-[29px] mr-1"src={goolgeLogo} alt='' />
              <span>Sign-in with Google</span> 
              </button>
             </div>
              </div>
            </div>
        </Modal>
   
        {/* ----logout */}
        <Modal
        onCancel={() => setopen(false)}
        footer={null}
        visible={open}
        // style={{ top: 70, right:0, }}
        style={{
          alignSelf: "flex-end",
          marginRight: "20px",
          marginTop: "-27px",
        }}
        width={278}
        closable={false}
      >
        <div
          className="w-[214px] h-[123px] mx-[8px] my-[6px] "
          style={{ fontFamily: "Montserrat, sans-serif " }}
        >
          <div className="w-auto h-[45px]   ">
            <h5 className="font-[700] text-[16px] leading-[10.5px]">Profile</h5>
            <h6 className="font-[500] text-[14px] leading-[20.07px] mt-[10px]">
              {userData?.name}
            </h6>
            <h6 className="font-[500] text-[14px] leading-[10.07px] mt-[10px]">
              {userData?.email}
            </h6>
          </div>
          <button
            className="bg-[#F2F2F2] w-[100%] h-[37px] mt-[32px] hover:bg-[#d8d6d6] text-[#575757] font-[600] text-[14px] py-2 px-4  rounded" id='signout-button'
            onClick={()=>{setlogoutAlert(true);setopen(false)}}
          >
            Log out
          </button>

        
        </div>
      </Modal>
   

   {/* logout alert modal */}
      <Modal
        onCancel={() => setlogoutAlert(false)}
        footer={null}
        visible={logoutAlert}
        width={378}
        >
           
           <div >
            <div className='flex justify-center items-center flex-col h-[150px]'>
              <img className='w-[80px]' src={Warning} alt=''/>
              <h1 className='text-[24px] font-bold mt-4'>Are you sure?</h1>
              <h4 className='text-[18px]  mt-2'>Do you want to logout</h4>
            </div>
              <div className='flex justify-between  mt-[20px]'>
                <button className=' border border-[grey] py-2 px-[35px] rounded-[4px] ml-2' id='alert-no'  onClick={()=>setlogoutAlert(false)}>No</button>
                <button className=' bg-[#DE6A29] py-2 px-[40px] text-[white] rounded-[4px] mr-2' id='alert-yes' onClick={HandleLogout}>Yes</button>
              </div>
           </div>


          

        </Modal>
  
      </div>


  
    </nav>
  );
};
export default Hamburger_Nav