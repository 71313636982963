import React, { useEffect, useState } from 'react'
import Innerimage from './Images/imageone.png';
import InnerImageTwo from './Images/imageColOne.png';
import InnerImageThree from "./Images/colImageTwo.png";
import InnerColLast from "./Images/colImageLast.png";
import OffersNav from '../components/Offers_Nav';
import Footer from '../components/Footer';
import HamburgerNav from '../components/Hamburger_Nav';
// import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import  { Modal } from 'antd'
import {singleViewActivity} from '../Utilis/BaseUrl.js';
import { Link } from 'react-router-dom';


//date picker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, eachDayOfInterval, isValid ,parse } from 'date-fns';
import './Styles/DatePicker.css'
import Logo from './Images/loaderLogo.png'
import img1 from './Images/Frame132.png'
import img2 from './Images/image123.png'
import Maldives_logo from '../components/Images/maldives_logo.svg'
import goolgeLogo from '../components/Images/Google_Icons.webp';
import { useGoogleLogin } from '@react-oauth/google';
import ShareUrl from '../components/ShareUrl.js';
import { useParams } from 'react-router-dom';
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import skeletonImage from './Images/sheleton loader image.png'
import RightArrow from './Images/right white arrow.svg'
import LeftArrow from './Images/left white arrow.svg'
import Warning from './Images/warning.png';
import Location from './Images/Location.png'





function ActivityBooking() {

  const navigate = useNavigate();
  
  const [date , setdate] = useState();
  const [noOfAdult , setNoOfAdult] = useState(0);
  const [ noOfChild , setNoOFChild ] = useState(0)
  const [loaderLoad , setloaderLoad] = useState(true)

   const name = 'Actitivty';
  const shareUrl = window.location.href //to get the current page url


   const [screenWidth, setScreenWidth] = useState(window.innerWidth);

      //date picker
      const [startDate, setStartDate] = useState(null);
      const [highlightedDates, setHighlightedDates] = useState([]);
      const [range, setRange] = useState(1); // Default to 7 days
      const [visible , setvisible] = useState(false)
      const [booking_date, setbooking_date] = useState('');
      const [booking_end_date, setbooking_end_date] = useState('');
      const [login ,setlogin] =  useState(false);
      const [userData,setUserDate] =  useState([])
      const [activity_startDate , setactivity_startDate] = useState('');
      const [activity_endDate , setactivity_endDate] = useState('');
      const [location , setLocation] = useState([]);
      const [totalCount , setTotalCount] = useState(1);
      const [count, setCount] = useState('');
      const [limitalert , setLimitalert] = useState(false)
      const [overridealert , setoverridealert] = useState(false)
      const [errorselect , seterrorselect] = useState("")
      const [errorlocation , seterrorlocation] = useState("")
      const [errordate , seterrordate] = useState("")
  
      
   
      const googlelogin = useGoogleLogin({
        onSuccess: async(response) =>{
          // console.log("first,",response)
        
          try {
            const res = await axios.get(
              "https://www.googleapis.com/oauth2/v3/userinfo",
              {
                headers:{
                  Authorization:`Bearer ${response.access_token}`,   
                },
                
              }
            );
 
            localStorage.setItem('myDate',JSON.stringify(res?.data));
            setUserDate(res?.data)

          } catch (error) {
            // console.log(error)
            navigate('/404')
            
          }
          setlogin(false)
        }
      
      });

      const [ActivityData,setActivityData] = useState([])

    // console.log("startDate",startDate)

  const passdate = ()=>{

    const total = noOfAdult + noOfChild
    setCount(total)
    // console.log("total",total)

    if( selectedLocation?.loc_book_lmt < total ){
      setLimitalert(true)
   } else {

    const sessionUserData = localStorage.getItem('myDate')
    if(sessionUserData){

      if (selectedLocation?.loc_name ){
        if(startDate != null && ActivityData.activity_name != null && ActivityData.activity_duration != null   ){
          if( noOfAdult > 0 || noOfChild > 0) {
           
            const activity_data = 
              {
                date:booking_date,
                noOfAdult:noOfAdult,
                noOfChild:noOfChild,
                activity_id:ActivityData.id,
                activity_name:ActivityData.activity_name,
                activity_duration:ActivityData.activity_duration,
                activity_price:selectedLocation?.loc_offer ? selectedLocation?.loc_offer : selectedLocation?.loc_price ,
                activity_location:selectedLocation.loc_name,
                activity_location_id : selectedLocation.id,
                
              }         
             sessionStorage.setItem('activity_data', JSON.stringify(activity_data));
             navigate("/Activity-booking")
             seterrorselect('')

          } else{
                toast.error("please enter travellers details")
                 seterrorselect("please enter travellers details")
            }
        }else{ toast.error("please select date")
                seterrordate("please select date")
  
          }
      }else{
            toast.error("Please choose a location")
            seterrorlocation("Please choose a location")
          }
        }
         
     else{
      //  toast.error("please login")
         setlogin(true)
     }  
    }
 
  }

 var _id
 const { id } = useParams();
   useEffect(()=>{

    // _id = (sessionStorage.getItem('Activity_id'))
    const listActivity = async ()=>{
      try{
          // var url = `https://gway.release.inaipi.ae/maldivesapi/activity/activityDetails`
          // const response = await axios.post(url,{_id:sessionStorage.getItem('Activity_id')})
          // if(response){
          //   setActivityData(response.data.data);   
          //   console.log("response.data.data===",response.data.data)                            
          // }
          let sessionActivityId = JSON.stringify({
            "_id": sessionStorage.getItem('Activity_id')
          });
          let ids = JSON.stringify({
            "_id":id
          });
           
          let data
          if(ids){
           data = ids
          }else{
           data = sessionActivityId
          }
      


          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${singleViewActivity}`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {

            // console.log(JSON.stringify(response.data));
            if(response.data.status === true){
            setActivityData(response.data.data); 
            setactivity_startDate(response.data.data?.activity_startDate);
            setactivity_endDate(response.data.data?.activity_endDate)
            setLocation(response.data.data?.location)
            // setTotalCount(response.data.data?.count)
            setloaderLoad(false)
            }else{
              navigate('/')
              
            }
            // console.log(response)
            // console.log("first",response.data.status)

          })
          .catch((error) => {
            // console.log("11111error");
            navigate('/404')

          });
      }catch(error){
          // console.log("error")
          navigate('/404')
      }
    };
    listActivity();

       //handle screen size
       const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };

    },[]);


    // console.log("location",location)
        //carousel
        const [currentIndex, setCurrentIndex] = useState(0);

        const prevSlide = () => {
          setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? 3 : prevIndex - 1
          );
        };
      
        const nextSlide = () => {
          setCurrentIndex((prevIndex) =>
            prevIndex === 3 ? 0 : prevIndex + 1
          );
        };

    //activity image loader
    const [ActivityIamgeLoad1, setActivityIamgeLoad1] = useState(true);     
    const [ActivityIamgeLoad2, setActivityIamgeLoad2] = useState(true);     
    const [ActivityIamgeLoad3, setActivityIamgeLoad3] = useState(true);     
    const [ActivityIamgeLoad4, setActivityIamgeLoad4] = useState(true);   

    const handleactivityimage1 = () => {
      setActivityIamgeLoad1(false)
   };
   const handleactivityimage2 = () => {
    setActivityIamgeLoad2(false)
   };
   const handleactivityimage3 = () => {
    setActivityIamgeLoad3(false)
   };
   const handleactivityimage4 = () => {
    setActivityIamgeLoad4(false)
   };

    function processImageContainer(){
      var processData = [];
      // console.log("============:",ActivityData.activity_image);
      if(ActivityData.activity_image == null){
        processData.push([]);
      }else{
        if(ActivityData.activity_image.length == 1){
          processData.push(
            <div className=" flex flex-row mt-4 gap-[2%] max-w-full h-[530px] ">
            <div className="w-3/4 h-full rounded-lg">
               <img className="w-full h-full" src={ActivityData.activity_image[0]} alt='' /> 
           </div>
            </div>
          )
        }else if(ActivityData.activity_image.length == 2){
          processData.push(
            <div className=" flex flex-row mt-4 gap-[2%] max-w-full h-[530px] ">
             <div className="w-3/4 h-full rounded-lg">
                <img className="w-full h-full" src={ActivityData.activity_image[0]} alt='' /> 
            </div>
            <div className="flex flex-col w-1/4 gap-[2%] h-full">
            <div className='h-1/3'>
                <img className="h-full" src={ActivityData.activity_image[1]} alt='' />
                </div>
                </div>
            </div>
          )
        }else if(ActivityData.activity_image.length == 3){
          processData.push(
            <div className=" flex flex-row mt-4 gap-[2%] max-w-full h-[530px] ">
            <div className="w-3/4 h-full rounded-lg">
               <img className="w-full h-full" src={ActivityData.activity_image[0]} alt='' /> 
           </div>
           <div className="flex flex-col w-1/4 gap-[2%] h-full">
           <div className='h-1/3'>
               <img className="h-full" src={ActivityData.activity_image[1]} alt='' />
               </div>
               <div className='h-1/3'>
               <img className="h-full" src={ActivityData.activity_image[2]} alt='' />
               </div>
               </div>
           </div>
          )
        }else if(ActivityData.activity_image.length == 4){
          processData.push(
            <>
               {screenWidth < 639 ? 
          
          <div className="relative w-full max-w-4xl mx-auto">
          <div className="overflow-hidden relative">
            <div
              className="flex transition-transform duration-300 ease-out h-[280px] "
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
            {ActivityIamgeLoad1 !== false  && (
              <img className="w-full flex-shrink-0 object-cover rounded-[8px] animate-pulse" src={skeletonImage} alt='' />
            )}   
              <img
                src={ActivityData?.activity_image[0]}
                alt="Slide 1"
                className="w-full flex-shrink-0 object-cover rounded-[8px]" onLoad={() => handleactivityimage1()} style={{ display: ActivityIamgeLoad1 !== false ? 'none' : 'block' }} 
              />
             {ActivityIamgeLoad2 !== false  && (
              <img className="w-full flex-shrink-0 object-cover rounded-[8px] animate-pulse" src={skeletonImage} alt='' />
            )}   
              <img
                src={ActivityData?.activity_image[1]}
                alt="Slide 2"
                className="w-full flex-shrink-0 object-cover rounded-[8px]" onLoad={() => handleactivityimage2()} style={{ display: ActivityIamgeLoad2 !== false ? 'none' : 'block' }} 
              />
             {ActivityIamgeLoad3 !== false  && (
              <img className="w-full flex-shrink-0 object-cover rounded-[8px] animate-pulse" src={skeletonImage} alt='' />
            )}   
              <img
               src={ActivityData?.activity_image[2]}
                alt="Slide 3"
                className="w-full flex-shrink-0 object-cover rounded-[8px]" onLoad={() => handleactivityimage3()} style={{ display: ActivityIamgeLoad3 !== false ? 'none' : 'block' }} 
              />
            {ActivityIamgeLoad4 !== false  && (
              <img className="w-full flex-shrink-0 object-cover rounded-[8px] animate-pulse" src={skeletonImage} alt='' />
            )}  
              <img
               src={ActivityData?.activity_image[3]}
                alt="Slide 4"
                className="w-full flex-shrink-0 object-cover rounded-[8px]" onLoad={() => handleactivityimage4()} style={{ display: ActivityIamgeLoad4 !== false ? 'none' : 'block' }} 
              />
            </div>
          </div>
          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 p-1 rounded-[50%] w-[30px] h-[30px]" id='scroll-left'
          >
           <img src={LeftArrow} width={80}/>

          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-4 transform -translate-y-1/2  bg-[#000000] bg-opacity-50 p-1 rounded-[50%] w-[30px] h-[30px]" id='scroll-right'
          >
            <img src={RightArrow}  width={80} />
          </button>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            <button id='setCurrentIndex(0)'
              onClick={() => setCurrentIndex(0)}
              className={`w-2 h-2 rounded-full ${currentIndex === 0 ? 'bg-white' : 'bg-gray-400'}`}
            />
            <button id='setCurrentIndex(1)'
              onClick={() => setCurrentIndex(1)}
              className={`w-2 h-2 rounded-full ${currentIndex === 1 ? 'bg-white' : 'bg-gray-400'}`}
            />
            <button id='setCurrentIndex(2)'
              onClick={() => setCurrentIndex(2)}
              className={`w-2 h-2 rounded-full ${currentIndex === 2 ? 'bg-white' : 'bg-gray-400'}`}
            />
            <button id='setCurrentIndex(3)'
              onClick={() => setCurrentIndex(3)}
              className={`w-2 h-2 rounded-full ${currentIndex === 3 ? 'bg-white' : 'bg-gray-400'}`}
            />
          </div>
        </div>

           
              :
           
              <div className="grid grid-cols-[872px,1fr] gap-[25px] h-[530px] 
              max-xl:grid-cols-[700px,1fr]
              max-lg:grid-cols-[500px,1fr] max-lg:h-[430px] max-lg:gap-[18px]
              max-md:grid-cols-[390px,1fr] max-md:h-[360px] max-md:gap-[10px]">
<div className='  max-lg:h-[430px] max-md:h-[360px]'>
{ActivityIamgeLoad1 !== false  && (<img className="object-cover w-[100%] h-[530px] rounded-[12px]
                  max-lg:h-[430px]
                  max-md:h-[360px]  animate-pulse" src={skeletonImage} alt='' />)}                                     
<img className=" object-cover w-[100%] h-[530px] rounded-[12px]
                  max-lg:h-[430px]
                  max-md:h-[360px]" src={ActivityData?.activity_image[0]} alt='' onLoad={() => handleactivityimage1()} style={{ display: ActivityIamgeLoad1 !== false ? 'none' : 'block' }}  />
</div>
<div className='  '>
  <div className='grid grid-flow-rows grid-rows-3 gap-3 max-lg:gap-y-[10px]'>
  <div className=' 
                    max-lg:h-[133px] 
                    max-md:h-[109px]'>
  {ActivityIamgeLoad2 !== false  && (<img className="w-full h-[166px] rounded-[12px]
                  max-lg:h-[133px]
                  max-md:h-[109px]  animate-pulse" src={skeletonImage} alt='' />)}                                     
  <img className="object-cover w-full h-[166px] rounded-[12px]
                  max-lg:h-[133px]
                  max-md:h-[109px] " src={ActivityData?.activity_image[1]} alt='' onLoad={() => handleactivityimage2()} style={{ display: ActivityIamgeLoad2 !== false ? 'none' : 'block' }}  />
  </div>
  <div className='  max-lg:h-[133px]
                    max-md:h-[109px]'>
{ActivityIamgeLoad3 !== false  && (<img className="w-[100%] h-[166px] rounded-[12px]
                max-lg:h-[133px] object-cover
                max-md:h-[109px]  animate-pulse" src={skeletonImage} alt='' />)}   
<img className="w-[100%] h-[166px] rounded-[12px]
                max-lg:h-[133px] object-cover
                max-md:h-[109px]" src={ActivityData?.activity_image[2]} alt='' onLoad={() => handleactivityimage3()} style={{ display: ActivityIamgeLoad3 !== false ? 'none' : 'block' }}  />
  </div>
  <div className='  max-lg:h-[132px]  max-md:h-[109px]'>
  {ActivityIamgeLoad4 !== false  && (<img className="w-[100%] h-[166px] rounded-[12px]
                  max-lg:h-[132px] object-cover
                  max-md:h-[109px]  animate-pulse" src={skeletonImage} alt='' />)}  
  <img className="w-[100%] h-[166px] rounded-[12px]
                  max-lg:h-[132px] object-cover
                  max-md:h-[109px]" src={ActivityData?.activity_image[3]} alt='' onLoad={() => handleactivityimage4()} style={{ display: ActivityIamgeLoad4 !== false ? 'none' : 'block' }}  />
  </div>
  </div>

</div>
</div> 
        }
           </>
          )
        }

      }
      return processData;
    }

    useEffect(() => {
      if (isValid(startDate)) {
        if (!doesRangeOverlapDisabledDates(startDate, range)) {
          const endDate = addDays(startDate, range - 1);
          const dates = eachDayOfInterval({ start: startDate, end: endDate });
          setHighlightedDates(dates);
          setbooking_date(formatDate(startDate));
          setbooking_end_date(formatDate(endDate));
        } else {
          // If the selected range overlaps with disabled dates, clear the selection
          setStartDate(null);
          setHighlightedDates([]);
          setbooking_date('');
          setbooking_end_date('');
        }
      } else {
        setHighlightedDates([]);
      }
    }, [startDate]);
  
  
    const handleDateChange = (date) => {
      if (isValid(date)) {
        if (!doesRangeOverlapDisabledDates(date, range)) {
          setStartDate(date);
          seterrordate('')
        } else {
          // alert('Selected date range overlaps with disabled dates.');
          setoverridealert(true)
        }
      } else {
        setStartDate(null);
      }
    };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB');
};

function ClearCalender() {
  setvisible(false);
  setbooking_date('');
  setbooking_end_date('');
  setHighlightedDates('')
  setStartDate(null)


}

// number input field of adult
const handleDecrement = () => {
  if (noOfAdult > 0) {
    setNoOfAdult(noOfAdult - 1);
    seterrorselect('');
  }
};


const handleIncrement = () => {
  setNoOfAdult(noOfAdult + 1);
  seterrorselect('');
};

const handleChange = (event) => {
  const value = event.target.value;
  // Only update the state if the input is a non-negative number
  if (/^\d*$/.test(value)) {
    setNoOfAdult(Number(value));
    
  }
};

// number input field of childs
const handleDecrementChild = () => {
  if (noOfChild > 0) {
    setNoOFChild(noOfChild - 1);
    seterrorselect('');
  }
};


const handleIncrementChild = () => {
  setNoOFChild(noOfChild + 1);
  seterrorselect('');
};

const handleChangeChild = (event) => {
  const value = event.target.value;
 
  // Only update the state if the input is a non-negative number
  if (/^\d*$/.test(value)) {
    setNoOFChild(Number(value));
    
  }
};


// download pdf 

const pdfUrl = ActivityData?.activity_pdf; // Replace with your actual PDF URL
const pdfName = "Activity details.pdf"; // Replace with your desired PDF name

const handleDownload = () => {


  const newTab = window.open(pdfUrl, '_blank');
  if (newTab) {
      newTab.location.href = pdfUrl;
      newTab.document.title = 'Activity details.pdf';
    };
  } 

  const dateObject = new Date(activity_startDate);
const dateObject2 = new Date(activity_endDate);

// Format the Date object to 'dd/MM/yyyy'
const formattedStartDate = dateObject?.toLocaleDateString('en-GB'); 
const formatteEndDate = dateObject2?.toLocaleDateString('en-GB'); 

    //disable calender 
    const disabledStartDate = parse(formattedStartDate, 'dd/MM/yyyy', new Date());
    const disabledEndDate = parse(formatteEndDate, 'dd/MM/yyyy', new Date());

    // Function to generate an array of dates to disable
    const generateDisabledDates = (startDate, endDate) => {
      const dates = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays(currentDate, 1);
      }
      return dates;
    };

    const disabledDates = generateDisabledDates(disabledStartDate, disabledEndDate);

    const isDateDisabled = (date) => {
      return disabledDates.some(disabledDate => date.getTime() === disabledDate.getTime());
    };

    const doesRangeOverlapDisabledDates = (start, range) => {
      const endDate = addDays(start, range - 1);
      const dates = eachDayOfInterval({ start, end: endDate });
      return dates.some(date => isDateDisabled(date));
    };


    //loaction funtion
    const [selectedLocation ,setSelectedLocation] = useState({});

    // useEffect(() => {
    //   if (location.length > 0) {
    //     setSelectedLocation(location[0]); // Set the first location as the default
    //   }
    // }, [location]);
  
    const onOptionChangeHandler = (event) => {
      const selectedLoc = location.find(loc => loc.loc_name === event.target.value);
      setSelectedLocation(selectedLoc);
      setTotalCount(selectedLoc?.loc_book_lmt)
      seterrorlocation('')

      // console.log("User Selected Value - ", selectedLoc);
  };


  // filtering location
  const filterLocation = location?.filter(loc => loc.loc_name !== '')

    // console.log("filterLocation",filterLocation)
  
    return (
    <div>
      {/* loader------ */}
   {loaderLoad === true ? 
    <div className='flex items-center justify-center flex-col  h-[100vh]   '>
      <img className='h-[60px]' src={Logo} alt='' />
      <div className="dots mt-3"></div>
     </div>
     :
     <>
      <OffersNav/>
      <HamburgerNav/>
      <div className=" mt-10 mx-auto h-auto max-w-[1184px] mb-[100px] 
                         max-xl:w-[980px] 
                         max-lg:w-[700px] 
                         max-md:w-[550px] 
                         max-sm:w-[100%] max-sm:px-[16px] "> 
      <div className='flex justify-between gap-x-4'>
        <div className='font-montserrat text-[24px] max-sm:text-[20px] font-bold leading-27 text-left'>{ActivityData?.activity_name}</div>
        <ShareUrl Url={shareUrl} name={name}/>
      </div>
        {ActivityData?.activity_duration === '1'? 
         <div className='mt-2 text-[#5F5F5F] font-montserrat text-base font-[500] leading-11 text-left mb-3'>Duration - {ActivityData?.activity_duration} Hour</div>
        :
        <div className='mt-2 text-[#5F5F5F] font-montserrat text-base font-[500] leading-11 text-left mb-3'>Duration - {ActivityData?.activity_duration} Hours</div>
      }

    
    {processImageContainer()}
  
    <div className=" flex  mt-4  gap-[2%] max-w-full  mb-[80px] 
                         max-md:flex-col">
        <div className="w-3/4 h-full 
                           max-lg:w-4/6
                          max-md:w-full ">
           <div className='bg-[#F9F9F9]  rounded-lg pl-4 flex items-center justify-between pr-3'>
           <div className='text-[#FD8062] font-montserrat font-semibold text-lg  leading-[66.88px]
                              max-md:text-[15px]'> Overview</div>
              {pdfUrl &&
                <button className='flex items-center gap-x-1 justify-center text-[12px] text-[#000000] bg-[white] border border-[#7f7ffd] rounded-[8px] h-[30px] px-2 ' onClick={handleDownload}>
                <h5>Download Activity Details</h5>
                {/* <img width={36} src={downloadIcon} alt=''/> */}
                <div className=' text-[15px] text-[#f44646]'>
                <HiOutlineDocumentArrowDown />
                </div>
              </button>   
              }              
             {/* <div className='text-[#505050] ml-4 font-montserrat text-base font-normal leading-[66.88px]
                              max-md:text-[15px]'>Address / Map</div> */}
          </div>
          <div className='font-montserrat text-base font-medium leading-[37px] tracking-tighter text-left mt-4 mb-4'>
            <div className=' w-[100%] h-[auto] mb-2'>
              <div className=' flex flex-wrap gap-3 ' style={{fontFamily:'"Montserrat", sans-serif'}}>
              {filterLocation?.map((location, index) => (
                <div className='min-w-[192px] h-[84px] border border-[#E6E6E6] rounded-[8px] px-[12px] py-[14px]'>
                  <div className='flex gap-1 items-center '>
                    <img src={Location} className='h-[15px]' alt='location'/>
                    <h3 className='font-[500] text-[15px] text-[#616161] text-center'>{location?.loc_name}</h3>
                  </div>
                  <div className='flex gap-1 items-center mt-1'>
                    {location?.loc_price != location?.loc_offer && 
                    <h1 className='text-[17px] text-[#333333]'><del>&#36; {location?.loc_price}</del></h1>
                    }
                    <h1 className='font-[600] text-[18px]'>&#36; {location?.loc_offer} </h1>
                    <span className='font-[400] text-[15px]'>per person</span>
                  </div>
                </div>
                ))}
              </div>
            </div>
          <div className='
                            max-md:text-[15px]  max-md:leading-[35px] 
                            max-sm:text-[13px]  max-sm:leading-[27px] ' dangerouslySetInnerHTML={{__html:ActivityData.activity_description}}/>
          </div>
        </div>
        <div className="flex w-1/4 flex-col gap-[2%] h-full 
                           max-lg:w-2/6
                          max-md:w-[100%]">
            <div className='md:h-1/2 lg:h-1/4
                             max-md:w-[100%]  '>
                      <>   
                      <div className=' mb-[16px]'>
                      <h6 className='font-[500] text-[11px] text-[#9A9A9A]'>Location</h6>
                       
                      <div className='mt-2 '>
                          <select className='font-montserrat text-xs font-medium text-[#9A9A9A]  cursor-pointer bg-[#F9F9F9] border border-[#E6E6E6] rounded-lg w-full p-1.5 h-[33px]  
                               max-xl:text-[10px] max-lg:text-[10px] max-md:text-xs max-md:h-[40px]' onChange={onOptionChangeHandler} >
                             <option> Choose a location  </option>
                          {filterLocation?.map((location, index) => {
                            return (
                               <option key={index} value={location.loc_name}> {location.loc_name}  </option>
                                   );
                          })}
                        </select>
                        {errorlocation && <h6 className='text-[red] mt-1 text-[11px] '>{errorlocation}</h6> }
                       
                      </div>
                       </div>  
                     <div className="h-full bg-[#F9F9F9] w-full rounded-lg px-4 py-4  max-md:w-full relative">
                      {ActivityData?.public_enabled === false ?
                      <div className="h-full bg-[#e2e2e2] w-full rounded-lg px-4 py-4  max-md:w-full absolute top-0 right-0 z-10 opacity-75 " >
                        <div className='w-full h-full flex justify-center items-center'> 
                          <div className='flex flex-col  items-center' >
                         <img src={Warning} className='w-[35px] mb-[8px]'/>
                          <h1 className='text-[black] font-[500] text-[14px] text-center leading-[24px] '> We're sorry! The selected Activity is currently unavailable. 
                          Please choose an alternative Activity or visit us later. Thank you for your understanding.</h1>
                          </div>
                        </div>
                      </div> :
                      ( totalCount <= '0' && <div className="h-full bg-[#e2e2e2] w-full rounded-lg px-4 py-4  max-md:w-full absolute top-0 right-0 z-10 opacity-75 " >
                      <div className='w-full h-full flex justify-center items-center'> 
                         <div className='flex flex-col ' >
                            <h1 className='text-left mb-2 font-[700]'>Package Limit Exceeded</h1>
                          <h1 className='text-[black] font-[500] text-[14px] text-left leading-[26px] '> The selected package  limit has been exceeded. Please choose a different package or contact us.</h1>
                          </div>
                      </div>
                    </div>) }
               
                       {selectedLocation?.loc_offer  && 
                     <div className='font-montserrat text-18 font-semibold leading-67 mb-[16px] 
                                max-lg:text-[14px] max-lg:mb-3
                                max-md:text-[14px]'>Starting from ${selectedLocation?.loc_offer ? selectedLocation?.loc_offer : selectedLocation?.loc_price} per person</div> }
            <div className='font-montserrat text-xs font-medium text-[#9A9A9A] mb-[8px] cursor-pointer bg-[white] border border-gray-300 rounded-lg w-full p-1.5 h-[33px] pt-[10px] max-md:pt-[13px] max-md:mb-2
                                max-lg:mb-2  max-xl:text-[10px] max-lg:text-[10px] max-md:text-xs max-md:h-[40px]' onClick={()=>setvisible(true)}>
                                  {startDate && highlightedDates.length > 0?
                                  <p> selected Date: {booking_date} </p> :"Select Date"}
                                  </div>
                          {errordate && <h6 className='text-[red] mt-1 text-[11px] '>{errordate}</h6> }
                <div className='font-montserrat text-xs font-medium text-[#9A9A9A] mb-[16px] mt-[8px]
                                max-lg:mb-2'>Travellers (No .of Adult) </div>
              <div className="relative flex items-center w-full  mb-[16px] max-md:mb-2 max-md:h-[40px] ">
                      <button type="button" id="decrement-button" data-input-counter-decrement="quantity-input" className=" dark:bg-white-700 dark:hover:bg-white-600 dark:border-white-600 hover:bg-white-200 border border-white-300 rounded-s-lg p-3 h-9  bg-[white]" onClick={handleDecrement}>
                        <svg className="w-2 h-1 text-gray-900 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h16" />
                        </svg>
                      </button>
                      <input type="text" id="quantity-input" data-input-counter aria-describedby="helper-text-explanation" className=" border-x-0 border-whute-300 h-9 text-center text-white-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-gray-400 dark:text-grey dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-[white] border" placeholder={0} required 
                      value={noOfAdult}
                      onChange={handleChange}/>
                      <button type="button" id="increment-button" data-input-counter-increment="quantity-input" className="dark:bg-white-700 dark:hover:bg-white-600 dark:border-white-600 hover:bg-white-200 border border-white-300 rounded-e-lg p-3 h-9 bg-[white]" onClick={handleIncrement}>
                        <svg className="w-2 h-2 mt-[-2px] text-gray-900 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 1v16M1 9h16" />
                        </svg>
                      </button>
                    </div>
                <div className='font-montserrat text-xs font-medium text-[#9A9A9A] mb-[16px]
                                  max-lg:mb-2'>Travellers (No .of Childern) </div>
                <div className="relative flex items-center w-full mb-[8px]  max-md:mb-2 max-md:h-[40px] ">
                      <button type="button" id="decrement-button-1" data-input-counter-decrement="quantity-input" className=" dark:bg-white-700 dark:hover:bg-white-600 dark:border-white-600 hover:bg-white-200 border border-white-300 rounded-s-lg p-3 h-9  bg-[white]" onClick={handleDecrementChild}>
                        <svg className="w-2 h-1 text-gray-900 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h16" />
                        </svg>
                      </button>
                      <input type="text" id="quantity-input" data-input-counter aria-describedby="helper-text-explanation" className=" border-x-0 border-whute-300 h-9 text-center text-white-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-white-700 dark:border-white-600 dark:placeholder-gray-400 dark:text-grey dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-[white] border" placeholder={0} required 
                      value={noOfChild}
                      onChange={handleChangeChild}/>
                      <button type="button" id="increment-button-1" data-input-counter-increment="quantity-input" className="dark:bg-white-700 dark:hover:bg-white-600 dark:border-white-600 hover:bg-white-200 border border-white-300 rounded-e-lg p-3 h-9 bg-[white]" onClick={handleIncrementChild}>
                        <svg className="w-2 h-2 mt-[-2px] text-gray-900 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 1v16M1 9h16" />
                        </svg>
                      </button>
                    </div>

                    {errorselect && <h6 className='text-[red] mb-1 mt-[-14px] text-[11px] inline-block'>{errorselect}</h6>}
             
                <button className='bg-black text-white w-full p-1.5 rounded-3xl font-montserrat text-16 font-semibold leading-17 text-center mt-[8px]' id='book-now' onClick={()=>{passdate()}}>Book Now</button>
                
              </div>
              </>
          </div>
        </div>
      </div>

    </div>
    <Modal onCancel={()=>ClearCalender()}
                 footer={null}
                 visible={visible}
                 width={280}
                >
                    <div className='flex justify-center items-center mt-4'>

                        <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                highlightDates={highlightedDates}
                inline
                minDate={new Date()}
                excludeDates={disabledDates} // Disable the date range
                />
                </div>
                <div className='flex items-center justify-between mt-2.5 mb-0'>
                  <button className='border bo rounded-[4px] px-3 py-2  bg-[#ffffff]' onClick={ClearCalender} id='cancel-button' >Cancel</button>
                  <button className='border rounded-[4px] px-[30px] py-2  bg-[#DE6A29] text-[WHITE] ' onClick={()=> setvisible(false)} id='select-date'>Select</button>
                </div>

            </Modal>
                        {/* login modal */}
                        <Modal onCancel={()=>setlogin(false)}
          footer={null}
          visible={login}
          width={969}
          //  wrapClassName="full-size-modal"
        >
            <div>
              <div className='flex  max-md:justify-center max-md:h-[300px]  '  style={{fontFamily:'"Montserrat", sans-serif'}}>
              {screenWidth >= 768 ?  
              <div className='relative' >
              <img src={img2} alt="Full-size content"  />
              <img className='absolute top-[30%] left-[25%] ' src={img1} alt="Full-size content"  />
              </div>
              :""}
             <div className='flex justify-center pt-[-90px] items-start mx-4 flex-col 
                               '>
              <img className='h-[41px]  max-sm:h-[32px]  max-sm:mb-5' src={Maldives_logo} alt=''/>
              <h1 className='text-[24px] font-[700] mt-2 
                             max-sm:text-[20px]  max-sm:mb-5'>Welcome to Trippers Maldives </h1>
              <button className='w-[100%] bg-[#EEEEEE] flex justify-center items-center py-[10px] mt-3 rounded-[8px]' id='google-login' onClick={() => googlelogin()}>
              <img className="h-[29px] mr-1"src={goolgeLogo} alt='' />
              <span>Sign-in with Google</span> 
              </button>
             </div>
              </div>
            </div>
                        </Modal>
              <Modal onCancel={()=> setLimitalert(false)}
                footer={null}
                visible={limitalert}
                width={480}
                //  wrapClassName="full-size-modal"
              >
                  <div>
                    <div className='flex  flex-col justify-center   mt-2 mb-2  '  style={{fontFamily:'"Montserrat", sans-serif'}}>
                      <h1 className='font-[700] text-[16px] mb-3 text-left'>Limited Spots Available</h1>
                      <h1 className='font-[500] text-[14px] leading-5 text-left'>The selected activity has only <strong>"{selectedLocation?.loc_book_lmt}"</strong> spots remaining. Your current selection exceeds the available spots. Please choose a different activity or contact us.</h1>
                      <span className='flex flex-col justify-center items-center'>
                      <p className='text-center mt-2'>OR</p>
                      <Link to="/contact-us" id='about-page' style={{ textDecoration: 'none', color: '#EAECF0;' }}>
                      <button className='bg-[#DE6A29] text-[white] w-[110px] h-[30px]  rounded-[8px] mt-2'> Contact us</button>
                      </Link>
                      </span>
                    </div>
                  </div>
                </Modal>

                <Modal onCancel={()=> setoverridealert(false)}
                footer={null}
                visible={overridealert}
                width={480}
                //  wrapClassName="full-size-modal"
              >
                  <div>
                    <div className='flex  flex-col justify-center   mt-2 mb-2  '  style={{fontFamily:'"Montserrat", sans-serif'}}>
                      {/* <h1 className='font-[700] text-[16px] mb-3 text-left'>Limited Spots Available</h1> */}
                      <h1 className='font-[500] text-[14px] leading-5 text-left'>This activity is unavailable for the selected date. Please choose another date.</h1>
                      <span className='flex flex-col justify-center items-center'>
                      <button className='bg-[#DE6A29] text-[white] w-[80px] h-[30px]  rounded-[8px] mt-2' onClick={()=>setoverridealert(false)}> Ok </button>
                      </span>
                    </div>
                  </div>
                </Modal>
    <Footer/>
    </>}
    </div>
  )
}

export default ActivityBooking


