import { Routes, Route, HashRouter } from 'react-router-dom'
import React, { Suspense } from 'react';
import './App.css';
import Home from './Pages/Home.js';
import ActivityBooking from './Pages/ActivityBooking.js';
import PackageBooking from './Pages/PackageBooking.js';
import Offers from './Pages/Offers.js';
import ActivityData from './Pages/ActivityData.js';
import "./components/Style/OffersNav.css"
import PackagesData from './Pages/PackagesData.js';
import PackageBooking_details from './Pages/PackageBooking_details.js';
import { Toaster } from 'react-hot-toast';
import ActivitiesBooking_details from './Pages/ActivitiesBooking_details.js';
import Saaample from './Pages/Saaample.js';

import Logo from './Pages/Images/loaderLogo.png'
import ContactAs from './Pages/ContactAs.js';
import BlobArticleOne from './Pages/BlobArticles/BlobArticleOne.js';
import PageNotFound from './Pages/404PageNotFound.js';
// import BlobArticleTwo from './Pages/BlobArticles/BlobArticleTwo.js';
// import BlobArticleThree from './Pages/BlobArticles/BlobArticleThree.js';
// import AboutAs from './Pages/AboutAs.js';
// import Terms from './Pages/Terms.js';
// import Privacy from './Pages/Privacy.js';


// lazy loading
// const BlobArticleOne = React.lazy(() => import('./Pages/BlobArticles/BlobArticleOne.js'));
const AboutAs = React.lazy(() => import('./Pages/AboutAs.js'));
const Terms = React.lazy(() => import('./Pages/Terms.js'));
const Privacy = React.lazy(() => import('./Pages/Privacy.js'));
const ErrorPage = React.lazy(() => import('./Pages/InternalErrorPage.js'));

function App() {


  return (
    <>
       <HashRouter>
        <Suspense fallback={ <div className='flex items-center justify-center flex-col  h-[100vh]  '>
                              <img className='h-[60px]' src={Logo} alt='' />
                              <div className="dots mt-3"></div>
                            </div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/packagesdata' element={<PackagesData />} />
          <Route path='/activitydata' element={<ActivityData />} />
          <Route path='/activitybooking/:name/:id' element={<ActivityBooking />} />
          <Route path='/packagebooking/:name/:id' element={<PackageBooking />} />
          <Route path='/offers' element={<Offers />} />
          <Route path='/details' element={<PackageBooking_details />} />
          <Route path='/Activity-booking' element={<ActivitiesBooking_details />} />
          <Route path='/BlogArticle/:id' element={<BlobArticleOne />} />
          <Route path='/AboutAs' element={<AboutAs/>} />
          <Route path='/Terms' element={<Terms/>} />
          <Route path='/Privacy' element={<Privacy/>} />
          <Route path='/Privacy' element={<Privacy/>} />
          <Route path='/contact-us' element={<ContactAs/>} />
          <Route path='/ErrorPage' element={<ErrorPage/>} />
          <Route path='/sample' element={<Saaample />} />
          <Route path='/404' element={<PageNotFound />} />
          <Route path='*' element={<PageNotFound/> } />


        </Routes>
        </Suspense>
      </HashRouter>
    <Toaster></Toaster>
      </>
   );
}

export default App;
